(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('IndustriasController', IndustriasController);

    IndustriasController.$inject = [];

    function IndustriasController () {
        var vm = this;

        vm.selectedTab = 1;
        vm.selectedCar = 1;

        vm.showTab = function (tab){
            vm.selectedTab = tab;
        }

        vm.selectCar = function (car){
            vm.selectedCar = car;
        }

        window.scrollTo(0, 0);
    }
})();
