(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', 'UserByFacebook', '$window'];

    function LoginController ($rootScope, $state, $timeout, Auth, $uibModalInstance, UserByFacebook, $window) {
        var vm = this;

        vm.authenticationError = false;
        vm.noClientError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.grant_type = 'password';
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.facebookLogin = facebookLogin;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true,
                grant_type: 'password'
            };
            vm.authenticationError = false;
            vm.noClientError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            if(event != null) {
                event.preventDefault();
            }

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                grant_type: vm.grant_type
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();

                $window.location.reload();

                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (resp) {
                if (resp.data.error === 'invalid_grant') {
                    vm.authenticationError = true;
                }

                if (resp.data.error === 'no_client') {
                    vm.noClientError = true;
                }
            });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function facebookLogin() {
            //Busco el estado del login del usuario con facebook
            FB.getLoginStatus(function(response) {
                //Si ya se encuentra logueado facebook y conectado a la aplicacion
                if (response.status === 'connected') {
                    //Logueo el usuario en Smart Eyes
                    loginWithFacebookData();
                } else {
                    //Sino muestro el cuadro de inicio de sesión de Facebook
                    FB.login(function(response) {
                        console.log(response.status);
                        //Si se conecto a Facebook, logueo el usuario en Smart Eyes
                        if (response.status === 'connected') {
                            //Busco en Facebook los datos del usuario
                            FB.api('/me', {
                                fields: 'id, first_name, last_name, email'
                            }, function (response) {
                                //Si no hubo ningun error buscando los datos
                                if (response && !response.error) {
                                    //Busco si ya existe en Smart Eyes el usuario logueado en Facebook
                                    UserByFacebook.get({facebookId: response.id}, function(result) {
                                        //Si no existe, lo creo
                                        if(result.Id == undefined) {
                                            var newUser = {
                                                FacebookId: response.id,
                                                Email: response.email,
                                                LangKey: 'en',
                                                UserName: response.email,
                                                Password: response.id,
                                                FirstName: response.first_name,
                                                LastName: response.last_name,
                                                Telefono: '00000000'
                                            };

                                            //Si el usuario esta logueado a Facebook mediante numero de telefono, y no tiene email
                                            //entonces no se puede loguear a Smart Eyes mediante Facebook
                                            if(newUser.Email != null) {
                                                Auth.createAccount(newUser).then(function () {
                                                    //una vez creado el usuario, lo logueo a Smart Eyes
                                                    loginWithFacebookData();
                                                });
                                            } else {
                                                vm.facebookLoginError = "No se puede loguear con Facebook si no se tiene email";
                                            }
                                        } else {
                                            //Si existe, directamente logueo al usuario en Smart Eyes
                                            loginWithFacebookData();
                                        }
                                    });
                                } else {
                                    vm.facebookLoginError = "Error al buscar los datos del usuario en Facebook";
                                }
                            });
                        }
                    }, {scope: 'public_profile, email'});
                }
            });
        }

        function loginWithFacebookData() {
            FB.api('/me', {
                fields: 'id, first_name, last_name, email'
            }, function (response) {
                vm.username = response.email;
                vm.password = response.id;
                vm.rememberMe = true;
                vm.grant_type = 'password';

                login();
            })
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
