(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('ubicacionEncuestaCliente', {
            templateUrl: 'app/components/ubicacionEncuestaCliente/ubicacionEncuestaCliente.html',
            controller: 'UbicacionEncuestaClienteController',
            controllerAs: 'vm',
            bindings: {
                encuesta: '=',
                changeTab: '&'
            }
        });
})();
