(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('membership-management', {
            parent: 'admin',
            url: '/membership-management',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Membresias'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/membresia/adminMembresia.html',
                    controller: 'AdminMembresiaController',
                    controllerAs: 'vm',
                    resolve: {
                        memberships: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.queryMemberships();
                        }],
                        items: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.queryItems();
                        }]
                    }
                }
            }
        });
    }
})();