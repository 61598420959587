(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('nuevaEncuestaCliente', {
            parent: 'app',
            url: '/nueva-encuesta-cliente/{id}',
            data: {
                authorities: []
            },
            params: {
                isDuplicated: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/nuevaEncuestaCliente/nuevaEncuestaCliente.html',
                    controller: 'NuevaEncuestaClienteController',
                    controllerAs: 'vm',
                    resolve: {
                        questionsTypes: ['PreguntaEncuestaClienteService', function (PreguntaEncuestaClienteService) {
                            return PreguntaEncuestaClienteService.queryQuestionsTypes();
                        }],
                        criteriosAceptacion: ['EncuestasClienteService', function(EncuestasClienteService) {
                            return EncuestasClienteService.getCriteriosAceptacion();
                        }]
                    }
                }
            }
        })
    }
})();