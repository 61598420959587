(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['CarritoProvider', 'encuestas', 'membership', 'nextMembership', 'encuestasSummary', 'contactosLibres'];

    function DashboardController (CarritoProvider, encuestas, membership, nextMembership, encuestasSummary, contactosLibres) {
        var vm = this;
        var carritoSubscription;

        function init () {
            window.scrollTo(0, 0);

            vm.encuestas = encuestas.Content;
            vm.encuestasSummary = encuestasSummary;
            vm.userMembresia = membership;
            vm.nextMembresia = nextMembership;
            vm.contactosLibres = contactosLibres;
            vm.costoEncuesta = 0;
            vm.descripcionContactosLibres = "Saldo Contactos (disponibles/total)";

            carritoSubscription = CarritoProvider.getCarritoSubject().subscribe(function (value) {
                vm.pending = value.length;
            });

            loadSummaryCharts(vm.encuestasSummary);
            loadEncuestaChart(encuestas.Content);
        }

        function loadEncuestaChart(encuestas) {
            vm.labels = [];
            vm.data = [];
            vm.colors = [];

            angular.forEach(encuestas, function (encuesta) {
                    if(encuesta.Estado.Nombre === 'APROBADA' || encuesta.Estado.Nombre === 'PENDIENTE_DE_APROBACION'){
                    vm.labels.push(encuesta.Nombre);
                    vm.data.push( (encuesta.Monto / encuesta.CantidadContactos).toFixed(2) );
                    vm.colors.push('#5c8be6');
                    }
            });
        }

        function loadSummaryCharts (encuestasSummary) {
            vm.doughnutChartOptions = {
                aspectRatio: 1,
                hover: {
                    mode: null
                },
                tooltips: {
                    enabled: false
                }
            };

            vm.doughnutChartColors = [
                '#5c8be6',
                '#d9e4eb'
            ];

            vm.activasChartData = [
                encuestasSummary.Activas,
                encuestasSummary.Total - encuestasSummary.Activas
            ];

            vm.pendientesChartData = [
                encuestasSummary.Pendientes,
                encuestasSummary.Total - encuestasSummary.Pendientes
            ];


            vm.vigentesChartData = [
                encuestasSummary.Vigentes,
                encuestasSummary.Total - encuestasSummary.Vigentes
            ];

            vm.finalizadasChartData = [
                encuestasSummary.Finalizadas,
                encuestasSummary.Total - encuestasSummary.Finalizadas
            ];
        }

        init();
    }
})();
