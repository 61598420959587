(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('carritoEncuestaCliente', {
            templateUrl: 'app/components/carritoEncuestaCliente/carritoEncuestaCliente.html',
            controller: 'CarritoEncuestaClienteController',
            controllerAs: 'vm',
            bindings : {
                encuesta: '=',
                validacionGuardar: '=',
                validacionAgregar: '='
            }
        });
})();
