(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'paginationConstants'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, paginationConstants) {
        var vm = this;

        function init () {
            vm.page = 1;
            vm.itemsPerPage = paginationConstants.itemsPerPage;
            vm.loadAll();
        }

        vm.loadAll = function () {
            var page ={};
            page.number = vm.page - 1;
            page.size = vm.itemsPerPage;

            User.query(page, onSuccess, onError);
        };

        function onSuccess(data) {
            vm.totalItems = data.TotalElements;
            vm.queryCount = vm.totalItems;
            vm.page = data.Number + 1;
            vm.users = data.Content;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        init();
    }
})();
