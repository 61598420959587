(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('HistorialComprasController', HistorialComprasController);

    HistorialComprasController.$inject = ['getHistorialCompras', 'HistorialComprasService'];

    function HistorialComprasController (getHistorialCompras, HistorialComprasService) {
        var vm = this;

        vm.historialCompras = getHistorialCompras.historialCompras;

        vm.uploadReceipt = function ($file, id) {
            HistorialComprasService.uploadReceipt($file, id);
        }

        window.scrollTo(0, 0);
    }
})();
