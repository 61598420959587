(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('AdminMembresiaEditItemController', AdminMembresiaEditItemController);

    AdminMembresiaEditItemController.$inject = ['item', '$uibModalInstance'];

    function AdminMembresiaEditItemController(item, $uibModalInstance) {
        var vm = this;

        function init () {
            vm.item = angular.copy(item);
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function() {
            $uibModalInstance.close(vm.item);
        };

        init();
    }
})();
