(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('NuevaEncuestaClienteController', NuevaEncuestaClienteController);

    NuevaEncuestaClienteController.$inject = ['$rootScope', '$state', '$location',
        '$scope', 'questionsTypes', '$stateParams', 'EncuestasClienteService', 'criteriosAceptacion'];

    function NuevaEncuestaClienteController ($rootScope, $state, $location, $scope,
                                             questionsTypes, $stateParams, EncuestasClienteService, criteriosAceptacion) {
        var vm = this;

        vm.questionsTypes = questionsTypes;

        vm.encuesta = {};

        vm.validacionGuardar = false;
        vm.validacionAgregar = false;
        vm.validateSave = validateSave;
        vm.validateAdd = validateAdd;
        vm.fechaInicioValida = new Date();
        vm.fechaVigenciaValida = new Date();
        vm.fechaInicioValida.setDate(vm.fechaInicioValida.getDate() + 2);
        vm.fechaVigenciaValida.setDate(vm.fechaInicioValida.getDate() + 5);
        vm.fechaVigencia = moment(vm.encuesta.FechaVigencia).format('L');
        vm.fechaInicio = moment(vm.encuesta.FechaInicio).format('L');
        vm.criteriosAceptacion = criteriosAceptacion;
        vm.labelDetallesEncuesta = "1 Detalles de la Encuesta";
        vm.labelPreguntasEncuesta = "2 Preguntas Encuesta";
        vm.labelUbicacionEncuesta = "3 Ubicación Encuesta";
        vm.labelCostoEncuesta = "4 Costo de la encuesta";

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        if(!$stateParams.id){
            vm.encuesta.Preguntas = [{ Descripcion: '', AccionType:'', Orden: '', AccionTypeObject: null, 
            Respuestas:[{ NumeroRespuesta: 1, Valor: '' }], Requerida: false,
            ControlPreventivo: { PrecioObjetivo: null, MinAlerta: null, MaxAlerta: null,
            MinCandado: null, MaxCandado: null }}];
        }
        if($stateParams.id){
            EncuestasClienteService.get($stateParams.id).then(function(result){
                vm.encuesta = result;
                angular.forEach(vm.encuesta.Preguntas, function(pregunta){
                    if(!pregunta.TipoPregunta.NombreFE) {
                        if(pregunta.TipoPregunta.Nombre == 'NUMERICO'){
                            pregunta.TipoPregunta.NombreFE = 'Numérico';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'ALFANUMERICO'){
                            pregunta.TipoPregunta.NombreFE = 'Alfanumérico';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'SI/NO'){
                            pregunta.TipoPregunta.NombreFE = 'Sí/No';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'GEOLOCALIZACION'){
                            pregunta.TipoPregunta.NombreFE = 'Geolocalización';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'IMAGEN'){
                            pregunta.TipoPregunta.NombreFE = 'Imágen';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'OPCION_MULTIPLE'){
                            pregunta.TipoPregunta.NombreFE = 'Opción múltiple';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'BARRA_DESPLAZABLE'){
                            pregunta.TipoPregunta.NombreFE = 'Barra desplazable';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'AUDIO'){
                            pregunta.TipoPregunta.NombreFE = 'Audio';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'VIDEO'){
                            pregunta.TipoPregunta.NombreFE = 'Video';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'CODIGO_QR'){
                            pregunta.TipoPregunta.NombreFE = 'Código QR';
                        }
                        if(pregunta.TipoPregunta.Nombre == 'PREGUNTA_CATALOGO'){
                            pregunta.TipoPregunta.NombreFE = 'Pregunta catálogo';
                        }
                    };
                    if($stateParams.isDuplicated){
                        vm.encuesta.isDuplicated = true;
                    }
                });
                vm.setQuestionsType();
                angular.forEach(vm.encuesta.Ubicaciones, function(ubicacion){
                   ubicacion.Pos = [ubicacion.Latitud, ubicacion.Longitud];
                });
            });
        }

        (function(){
            if (window.matchMedia("(max-width: 767px)").matches) {
                vm.labelDetallesEncuesta = "1";
                vm.labelPreguntasEncuesta = "2";
                vm.labelUbicacionEncuesta = "3";
                vm.labelCostoEncuesta = "4";
            }
            else {
                vm.labelDetallesEncuesta = "1 Detalles de la Encuesta";
                vm.labelPreguntasEncuesta = "2 Preguntas Encuesta";
                vm.labelUbicacionEncuesta = "3 Ubicación Encuesta";
                vm.labelCostoEncuesta = "4 Costo de la encuesta";
            }
        })();

        vm.setQuestionsType = function () {
            if(!vm.encuesta.audioVideoQuestions) {
                vm.encuesta.audioVideoQuestions = {};
                vm.encuesta.audioVideoQuestions.cantidad = 0;
            }
            if(!vm.encuesta.imageQuestions) {
                vm.encuesta.imageQuestions = {};
                vm.encuesta.imageQuestions.cantidad = 0;
            }
            if(!vm.encuesta.simpleQuestions) {
                vm.encuesta.simpleQuestions = {};
                vm.encuesta.simpleQuestions.cantidad = 0;
            }
            if(!vm.encuesta.totalQuestions) {
                vm.encuesta.totalQuestions = {};
                vm.encuesta.totalQuestions.cantidad = 0;
            }

            angular.forEach(vm.encuesta.Preguntas, function (pregunta) {
                if(pregunta.TipoPregunta.ActionType === 7 || pregunta.TipoPregunta.ActionType === 8){
                    vm.encuesta.audioVideoQuestions.cantidad += 1;
                } else if (pregunta.TipoPregunta.ActionType === 4){
                    vm.encuesta.imageQuestions.cantidad += 1;
                }else {
                    vm.encuesta.simpleQuestions.cantidad += 1;
                }
                vm.encuesta.totalQuestions.cantidad++;
            });
        };

        vm.selectedIndex = 0;

        vm.changeTab  = function () {
            vm.selectedIndex++;
            if(vm.selectedIndex === 3){
               vm.validacionGuardar = validateSave();
               vm.validacionAgregar = validateAdd();
            }
        };

        vm.validacion  = function () {
            vm.validacionGuardar = validateSave();
            vm.validacionAgregar = validateAdd();
        };

        function validateSave() {
            if( vm.encuesta.Nombre === "" || vm.encuesta.Nombre === undefined)
            {
                return false;
            }
            if( vm.encuesta.Descripcion === "" || vm.encuesta.Descripcion === undefined)
            {
                return false;
            }
            if( vm.encuesta.FechaInicio === "" || vm.fechaInicioValida >= vm.encuesta.FechaInicio)
            {
                return false;
            }
            if( vm.encuesta.FechaVigencia === "" || vm.fechaVigenciaValida >= vm.encuesta.FechaVigencia)
            {
                return false;
            }
            if( vm.encuesta.TipoEncuesta === "" || vm.encuesta.TipoEncuesta === undefined)
            {
                return false;
            }
            if( vm.encuesta.CriterioAceptacion === "" || vm.encuesta.CriterioAceptacion === undefined)
            {
                return false;
            }
            return true;

        }

        function validateAdd() {
            if( vm.encuesta.FechaInicio === "" || vm.fechaInicioValida >= vm.encuesta.FechaInicio)
            {
                return false;
            }
            if( vm.encuesta.FechaVigencia === "" || vm.fechaVigenciaValida >= vm.encuesta.FechaVigencia)
            {
                return false;
            }
            if( vm.encuesta.Preguntas.length === 0){
                return false;
            }
            vm.valido = true;
            vm.encuesta.Preguntas.forEach(function(element) {
                if(element.TipoPregunta){
                    if(element.TipoPregunta.Nombre === ""){
                        vm.valido = false;
                        return false;
                    }
                    if(element.Texto === "" || !element.Texto && element.TipoPregunta.Nombre != 'PREGUNTA_CATALOGO'){
                        vm.valido = false;
                        return false;
                    }
                }
                else{
                    vm.valido = false;
                    return false;
                }
            });
            if(!vm.valido){
                return false;
            }
            if( vm.encuesta.Ubicaciones.length === 0){
                return false;
            }
            return true;
        }

        vm.confirmEncuesta = function () {
            if (vm.validateForm() && vm.validateQuestion()) {
                vm.encuesta.Nombre = vm.nombre;
                vm.encuesta.Descripcion = vm.descripcion;
                vm.encuesta.CriteriosAceptacion = vm.criterios;
                vm.encuesta.FechaInicio = vm.fechaInicio;
                vm.encuesta.FechaVigencia = vm.fechaVigencia;
                vm.encuesta.Activo = 1;
                vm.encuesta.Exito = 50;
                vm.encuesta.Fracaso = 50;
                vm.encuesta.Auditores = vm.auditores;

                /*vm.listaMapItems = [];
                vm.listaMapName = [];

                vm.nombreListaMap = sharedProperties.getListName(); //Obtenemos el nombre de la lista desde map-controller

                vm.listaMapItems = sharedProperties.getItems();
                if (vm.nombreListaMap) {
                    for (var i = 0; i < vm.listaMapItems.length; i++)
                    {
                        vm.listaMapItems[i].NombreLista = vm.nombreListaMap.toString();
                    }
                    var ListaNombre = vm.nombreListaMap.toString()
                }
                var nList = { ListaNombre }
                vm.listaMapName.push(nList);

                params = { Encuesta: $scope.encuesta, Preguntas: $scope.preguntas, Respuestas: $scope.respuestas, Ubicaciones: $scope.listaMapItems, Lista: $scope.listaMapName };
                vm.showAdvanced(params);*/
            }
        };
    }
})();