(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('CotizadorService', CotizadorService);

    CotizadorService.$inject = ['MembresiaService', 'CarritoService'];

    function CotizadorService(MembresiaService, CarritoService) {
        var userMembresia = null;
        var contactosLibres = 0;
        var preguntasLibres = 0;
        var accesoComunidad = 0;
        var bonificaciones = [[], []];
        var hasMembership = false;


        MembresiaService.getUserMembership().then(function (response) {
            userMembresia = response ? response : null;
            hasMembership = (userMembresia != null);
            preguntasLibres = hasMembership ? userMembresia.CantidadPreguntaEncuesta : 0;
        });

        MembresiaService.getContactosLibres().then(function (response) {
            contactosLibres = response;
        });

        CarritoService.getCotizadorVariable('ACCESO_COMUNIDAD').then(function (response) {
            accesoComunidad = hasMembership ? 0 : response.data.Valor;
        });

        CarritoService.queryCotizadorBonificacion().then(function (response) {
            bonificaciones = response.data;
        });

        return {
            getTotal: getTotal,
            getBonificacion: getBonificacion,
            getPrecioEncuesta: getPrecioEncuesta,
            reloadContactosLibres: reloadContactosLibres
        };

        function reloadContactosLibres() {
            MembresiaService.getContactosLibres().then(function (response) {
                contactosLibres = response;
            });

            MembresiaService.getUserMembership().then(function (response) {
                userMembresia = response ? response : null;
                hasMembership = (userMembresia != null);
                preguntasLibres = hasMembership ? userMembresia.CantidadPreguntaEncuesta : 0;
            });
        }

        function getPrecioPreguntas(encuesta, isFloating) {
            if(!isFloating){
                var exPreguntas = Math.max(encuesta.Preguntas.length - preguntasLibres, 0);
            }

            if(encuesta.Preguntas){
                var total = encuesta.Preguntas
                    .map(function (pregunta) {
                        if(pregunta.TipoPregunta){
                            return pregunta.TipoPregunta.Precio;
                        }else{
                            return 0;
                        }
                    })
                    .sort()
                    .reverse()
                    .slice(0, exPreguntas)
                    .reduce(function (n, x) {
                        return n+x;
                    }, 0);

                return total;
            }
            else{
                return 0;
            }
        }

        function getPrecioEncuesta(encuesta, isFloating) {
            if(!isFloating){
                var exContactos = Math.max(encuesta.CantidadContactos - contactosLibres, 0);
            }else{
                var exContactos = encuesta.CantidadContactos;
            }

            var totalPreguntas = getPrecioPreguntas(encuesta, isFloating);

            encuesta.precioContacto = Math.max(250, totalPreguntas);

            if (hasMembership && !isFloating){
                encuesta.precioContacto *= (1 - userMembresia.DescuentoContacto / 100);
            }

            var total = exContactos * encuesta.precioContacto;

            if (totalPreguntas > 0 && exContactos == 0)
                total += totalPreguntas;

            return total;
        }

        function getBonificacion(total) {
            for (var i=0;i<bonificaciones[0].length;i++) {
                if (total >= bonificaciones[0][i].Valor)
                    return bonificaciones[1][i].Valor;
            }

            return 0;
        }

        function getTotal(encuestas, hasMembership, isFloating) {
            var total = encuestas.reduce(function (n, encuesta) {
                return n + getPrecioEncuesta(encuesta, isFloating);
            }, 0);
            if(hasMembership)
            {
                accesoComunidad = 0;
            }
            return total * (1 - getBonificacion(total)) + accesoComunidad;
        }
    }
})();