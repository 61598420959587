(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('UbicacionEncuestaClienteLocationsService', UbicacionEncuestaClienteLocationsService);

    UbicacionEncuestaClienteLocationsService.$inject = ['$q', '$http'];

    function UbicacionEncuestaClienteLocationsService ($q, $http) {
        return {
            queryCadenasLists: queryCadenasLists,
            queryTiendasLists: queryTiendasLists,
            queryEstadosLists: queryEstadosLists,
            queryMunicipiosLists: queryMunicipiosLists,
            queryListsByFilter : queryListsByFilter
        };

        function queryCadenasLists() {
            var deferred = $q.defer();
            $http.get('api/ubicacion/filter/findAllCadenas').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function queryTiendasLists() {
            var deferred = $q.defer();
            $http.get('api/ubicacion/filter/findAllTiendas').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function queryEstadosLists() {
            var deferred = $q.defer();
            $http.get('api/ubicacion/filter/findAllEstados').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
        function queryMunicipiosLists() {
            var deferred = $q.defer();
            $http.get('api/ubicacion/filter/findAllMinicipios').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
        function queryListsByFilter(filtros) {
            var deferred = $q.defer();
            console.log(filtros);
            $http.get('api/ubicacion/filter/findListsByFilter?Cadena='+filtros.Cadena+'&Tienda='+filtros.Tienda+'&Estado='+filtros.Estado+'&Municipio='+filtros.Municipio).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

    }
})();