(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('AdminMembresiaEditMembershipController', AdminMembresiaEditMembershipController);

    AdminMembresiaEditMembershipController.$inject = ['membership', 'items', '$uibModalInstance'];

    function AdminMembresiaEditMembershipController(membership, items, $uibModalInstance) {
        var vm = this;

        function init () {
            vm.membership = Object.assign({}, membership);
            vm.selected = Object.assign({}, vm.membership.items);

            vm.items = items;
        }

        vm.select = function (item) {
            vm.selected[item.Nombre] = !vm.selected[item.Nombre];
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function() {
            vm.membership.Items = [];

            for (var i=0;i<items.length;i++)
                if (vm.selected[items[i].Nombre])
                    vm.membership.Items.push(items[i]);

            $uibModalInstance.close(vm.membership);
        };

        init();
    }
})();
