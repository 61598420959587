(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('datosEncuestaCliente', {
            templateUrl: 'app/components/datosEncuestaCliente/datosEncuestaCliente.html',
            controller: 'DatosEncuestaClienteController',
            controllerAs: 'vm',
            bindings: {
                encuesta: '=',
                changeTab: '&',
                criterios: '<'
            }
        });

})();
