(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('cliente-management', {
            parent: 'admin',
            url: '/cliente-management',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Clientes'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/cliente-management/cliente-management.html',
                    controller: 'ClienteManagementController',
                    controllerAs: 'vm'
                }
            }
        })
        .state('cliente-management.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/cliente-management/cliente-management-dialog.html',
                    controller: 'ClienteManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                Id: null,
                                Nombre: null,
                                Email: null,
                                Logo: null,
                                LogoContentType: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('cliente-management', null, { reload: true });
                }, function() {
                    $state.go('cliente-management');
                });
            }]
        })
        .state('cliente-management.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/cliente-management/cliente-management-dialog.html',
                    controller: 'ClienteManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Cliente', function(Cliente) {
                            return Cliente.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cliente-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('cliente-management.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/cliente-management/cliente-management-delete-dialog.html',
                    controller: 'ClienteManagementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Cliente', function(Cliente) {
                            return Cliente.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cliente-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
