(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('preguntaEncuestaCliente', {
            templateUrl: 'app/components/preguntaEncuestaCliente/preguntaEncuestaCliente.html',
            controller: 'PreguntaEncuestaClienteController',
            controllerAs: 'vm',
            bindings: {
                questionsTypes: '<',
                encuesta: '=',
                changeTab: '&'
            }
        });
})();
