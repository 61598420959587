(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('encuestasCliente', {
            parent: 'app',
            url: '/encuestas-cliente',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/encuestasCliente/encuestasCliente.html',
                    controller: 'EncuestasClienteController',
                    controllerAs: 'vm',
                    resolve: {
                        user : ['Principal', function (Principal) {
                            return Principal.identity();
                        }],
                        encuestas: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.queryEncuestasClienteByPage(0);
                        }],
                        encuestasSummary: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.summary();
                        }]
                    }
                }
            }
        })
        .state('encuestasCliente.detail', {
            parent: 'encuestasCliente',
            url: '/{id}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/encuestasCliente/encuestasCliente-detail.html',
                    controller: 'EncuestasClienteDetailController',
                    controllerAs: 'vm',
                    resolve: { 
                        encuesta: ['EncuestasClienteService', function (EncuestasClienteService) {
                                return EncuestasClienteService.get($stateParams.id);
                            }]
                    },
                    size: 'md'
                }).result.then(function() {
                    $state.go('encuestasCliente', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();