(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('MembresiaService', MembresiaService);

    MembresiaService.$inject = ['$q', '$http'];

    function MembresiaService ($q, $http) {
        return {
            queryMemberships: queryMemberships,
            queryItems: queryItems,
            getUserMembership: getUserMembership,
            getMembresiaCarrito: getMembresiaCarrito,
            getMembresiaToApprove: getMembresiaToApprove,
            cancelMembership: cancelMembership,
            getNextMembership: getNextMembership,
            getMembership: getMembership,
            getContactosLibres: getContactosLibres,
            getPreguntasLibres: getPreguntasLibres,
            createMembership: createMembership,
            deleteMembership: deleteMembership,
            updateMembership: updateMembership,
            createItem: createItem,
            deleteItem: deleteItem,
            updateItem: updateItem,
            // addToCart: addToCart,
            suscribe: suscribe,
            getApprovalLink: getApprovalLink,
            executeAgreement: executeAgreement,
            cancelMembresiaToApprove: cancelMembresiaToApprove,
            approveMembresia: approveMembresia
        };

        function queryMemberships() {
            var deferred = $q.defer();

            $http.get('api/membresias').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function cancelMembership() {
            var deferred = $q.defer();

            $http.get('api/membresias/cancel').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        function getContactosLibres() {
            var deferred = $q.defer();

            $http.get('api/membresias/contactos').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getPreguntasLibres() {
            var deferred = $q.defer();

            $http.get('api/membresias/preguntas').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryItems() {
            var deferred = $q.defer();

            $http.get('api/membresias/items').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getUserMembership() {
            var deferred = $q.defer();

            $http.get('api/membresias/byLoggedUser').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getMembresiaCarrito() {
            var deferred = $q.defer();

            $http.get('api/membresias/carrito').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getMembresiaToApprove() {
            var deferred = $q.defer();

            $http.get('api/membresias/to-approve').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getMembership(id) {
            var deferred = $q.defer();

            $http.get('api/Membresias/' + id).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getNextMembership() {
            var deferred = $q.defer();

            $http.get('api/membresias/nextByUserLogged').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function createMembership(membership) {
            return $http.post('api/membresias', membership);
        }

        function deleteMembership(membership) {
            return $http.delete('api/membresias?id='+membership.Id);
        }

        function updateMembership(membership) {
            return $http.put('api/membresias', membership);
        }

        function createItem(item) {
            return $http.post('api/items', item);
        }

        function deleteItem(item) {
            return $http.delete('api/items?id='+item.Id);
        }

        function updateItem(item) {
            return $http.put('api/items', item);
        }

        // function addToCart(encuesta){
        //     var deferred = $q.defer();
        //
        //     save(encuesta).then(function(response){
        //         $http.post('api/membresias/addToCart', response).then(function (result) {
        //             deferred.resolve(result.data);
        //         }).catch(function (error) {
        //             deferred.reject(error);
        //         });
        //     });
        //
        //     return deferred.promise;
        // }

        function suscribe(membresiaId){
            var deferred = $q.defer();

            $http.post('api/membresias/suscribe/' + membresiaId).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getApprovalLink(id) {
            var deferred = $q.defer();

            $http.get('api/membresias/GetPayPalApproval_url?id_membresia=' + id).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function executeAgreement(agreementToken) {
            return $http.get('api/membresias/ExecuteAgreement?agreementToken=' + agreementToken);
        }

        function cancelMembresiaToApprove(id){
            var deferred = $q.defer();

            $http.post('api/membresias/CancelMembresiaToApprove', id).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function approveMembresia(id){
            var deferred = $q.defer();

            $http.post('api/membresias/ApproveMembresia', id).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
    }
})();