(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('MembresiaContratarDialogController', MembresiaContratarDialogController);

    MembresiaContratarDialogController.$inject = ['$uibModalInstance', 'membership', '$http', 'userMembership', 'userCard'];

    function MembresiaContratarDialogController ($uibModalInstance, membership, $http, userMembership, userCard) {
        var vm = this;

        vm.membership = membership;
        vm.hasMembership = userMembership != null;
        vm.showdialogcard = false;

        vm.userCard = userCard.data;
        if(vm.userCard == null){
            vm.showdialogcard = true;
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.modalAcept = function(){
            $uibModalInstance.dismiss('ok');
        };

        vm.suscribe = function () {
            $http.post('api/membresias/suscribe/'+membership.Id, {}).then(function(){
                $uibModalInstance.close('ok');
            }).catch(function (error) {
                console.log(error);
            });
        };
    }
})();