(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('EncuestasClienteDeleteController', EncuestasClienteDeleteController);

    EncuestasClienteDeleteController.$inject = ['encuesta', '$uibModalInstance', 'EncuestasClienteService'];

    function EncuestasClienteDeleteController (encuesta, $uibModalInstance, EncuestasClienteService) {
        var vm = this;

        function init () {
            vm.encuesta = encuesta;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.remove = function() {
            EncuestasClienteService.deleteEncuesta(vm.encuesta);
            $uibModalInstance.close(vm.encuesta);
        };

        init();
    }
})();
