(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ConfirmacionMembresiaController', ConfirmacionMembresiaController);

    ConfirmacionMembresiaController.$inject = ['CarritoProvider', '$uibModalInstance'];

    function ConfirmacionMembresiaController(CarritoProvider, $uibModalInstance) {
        var vm = this;

        function init () {
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        init();
    }
})();