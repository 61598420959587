(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('smarteyesjhApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_TIMESTAMP', 1626387522586)
        .constant('API_URL', "")
        .constant('BACKOFFICE_API_URL', "http://hcoffice.sytes.net:9001")
;
})();
