(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('EncuestasClienteService', EncuestasClienteService);

    EncuestasClienteService.$inject = ['$q', '$http', 'API_URL', '$window'];

    function EncuestasClienteService ($q, $http, API_URL, $window) {
        return {
            approve: approve,
            get: get,
            queryEncuestasCliente: queryEncuestasCliente,
            queryEncuestasClienteByEstado: queryEncuestasClienteByEstado,
            queryEncuestasClienteByName: queryEncuestasClienteByName,
            createFromExcel: createFromExcel,
            queryEncuestasClienteByPage: queryEncuestasClienteByPage,
            save: save,
            addToCart: addToCart,
            summary: summary,
            removeFromCart: removeFromCart,
            deleteEncuesta: deleteEncuesta,
            getEncuestaResults: getEncuestaResults,
            getEncuestadoresByEncuestaResults: getEncuestadoresByEncuestaResults,
            getResultEstadisticasEfectividad: getResultEstadisticasEfectividad,
            getResultsByEncuestadorId: getResultsByEncuestadorId,
            getResultEstadisticas: getResultEstadisticas,
            exportResultsToCSV: exportResultsToCSV,
            exportResultsToPDF: exportResultsToPDF,
            getCriteriosAceptacion: getCriteriosAceptacion,
            getImage: getImage,
            sendMediaEmail: sendMediaEmail,
            checkForFile: checkForFile,
            getLinkForFile: getLinkForFile,
            buscarGeosTodos: buscarGeosTodos,
            getEncuestaBySitiowebId: getEncuestaBySitiowebId,
            getMediaForCarousel: getMediaForCarousel
        };

        function get(encuestaId) {
            var deferred = $q.defer();

            $http.get('api/encuestas/' + encuestaId).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function deleteEncuesta (encuesta) {
            var deferred = $q.defer();

            $http({
                method: 'DELETE',
                url: 'api/encuestas',
                data: encuesta,
            headers: {
                'Content-type': 'application/json;charset=utf-8'
            }
            }).then(function (response) {
                deferred.resolve(response);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function approve(encuestaId) {
            var deferred = $q.defer();

            $http.post('api/encuestas/approve', { Id: encuestaId }).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryEncuestasCliente() {
            var deferred = $q.defer();

            $http.get('api/encuestas').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryEncuestasClienteByPage(page) {
            var deferred = $q.defer();

            $http.get('api/encuestas?Number=' + page).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryEncuestasClienteByEstado(state) {
            var deferred = $q.defer();

            $http.get('api/encuestas/estado/' + state).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryEncuestasClienteByName(nombre, page) {
            var deferred = $q.defer();

            $http.get('api/encuestas/nombre/' + nombre + '?Number=' + page).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function createFromExcel(formData) {
            var deferred = $q.defer();

            var excelDTO = {};
            excelDTO.name = 'nico';
            excelDTO.file = formData;

            $http.post('api/encuestas/create-from-excel', excelDTO).then(function (result) {
                deferred.resolve(result.data);
            }).catch(function (error) {
                deferred.reject(error);
            });


            return deferred.promise;
        }

        function addToCart(encuesta){
            var deferred = $q.defer();

            save(encuesta).then(function(response){
                $http.post('api/encuestas/addToCart', response).then(function (result) {
                    deferred.resolve(result.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            });

            return deferred.promise;
        }

        function removeFromCart (encuesta) {
            var deferred = $q.defer();

            $http.post('api/encuestas/removeFromCart', encuesta).then(function (response) {
                deferred.resolve(response);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function save(encuesta) {
            var deferred = $q.defer();

            if(!encuesta.Id){
                $http.post('api/encuestas', encuesta).then(function (response) {
                    deferred.resolve(response.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            } else{
                $http.put('api/encuestas', encuesta).then(function (response) {
                    deferred.resolve(response.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        function summary() {
            var deferred = $q.defer();

            $http.get('api/encuestas/summary').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getEncuestaResults (idEncuesta, aprobados, fechaDesde, fechaHasta) {
            var deferred = $q.defer();

            var pageData = {
                "Size" : 12,
                "Number" : 0
            };

            if(!aprobados)
            {
                aprobados = false;
            }

            if(!fechaDesde || !fechaHasta){
                $http.post('api/encuestas/results?aprobados=' + aprobados + '&encuestaId=' +
                    idEncuesta + '&fechaDesde=null&fechaHasta=null', pageData).then(function (response) {
                    deferred.resolve(response);
                }).catch(function(error){
                    deferred.reject(error);
                });
            }else{
                $http.post('api/encuestas/results?aprobados=' + aprobados + '&encuestaId=' +
                    idEncuesta + '&fechaDesde=' + fechaDesde.getTime() + '&fechaHasta=' + fechaHasta.getTime(), pageData).then(function (response) {
                    deferred.resolve(response);
                }).catch(function(error){
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        function getEncuestadoresByEncuestaResults(page, idEncuesta, aprobados, fechaDesde, fechaHasta, nombre) {
            var deferred = $q.defer();

            var pageData = {
                "Size" : 12,
                "Number" : page,
            };

            if(!aprobados)
            {
                aprobados = false;
            }

            if(!fechaDesde || !fechaHasta){
                $http.post('api/encuestas/results/auditors?aprobados=' + aprobados + '&encuestaId=' +
                    idEncuesta + '&fechaDesde=null&fechaHasta=null&nombre=' + nombre, pageData).then(function (response) {
                    deferred.resolve(response);
                }).catch(function(error){
                    deferred.reject(error);
                });
            }else{
                $http.post('api/encuestas/results/auditors?aprobados=' + aprobados + '&encuestaId=' +
                    idEncuesta + '&fechaDesde=' + fechaDesde.getTime() 
                    + '&fechaHasta=' + fechaHasta.getTime() + '&nombre=' + nombre, pageData).then(function (response) {
                    deferred.resolve(response);
                }).catch(function(error){
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        function getResultsByEncuestadorId(encuestaId, encuestadorId, aprobados, fechaDesde, fechaHasta, numeroInstancia, timeZone) {
            var deferred = $q.defer();

            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            $http.post('api/encuestas/results/encuesta/' + encuestaId 
                    + '/auditor/' + encuestadorId + '/approveOnly/' + aprobados 
                    + '?fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&numeroInstancia=' + numeroInstancia
                    + '&timeZone=' + timeZone).then(function (response) {
                deferred.resolve(response);
            }).catch(function(error){
                deferred.reject(error);
            });
            
            return deferred.promise;
        }

        function getResultEstadisticas(encuestaId, encuestadorId, aprobados, instancia, fechaDesde, fechaHasta) {
            var deferred = $q.defer();

            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            $http.post('api/encuestas/results/estadisticas/encuesta/' + encuestaId 
                    + '/auditor/' + encuestadorId + '/approveOnly/' + aprobados
                    + '?instancia=' + instancia
                    + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta).then(function (response) {
                deferred.resolve(response);
            }).catch(function(error){
                deferred.reject(error);
            });
            
            return deferred.promise;
        }


        function exportResultsToCSV(encuestaID, aprobados, fechaDesde, fechaHasta, encuestadorId, timeZone) {
            if(!aprobados)
                aprobados = false;

            if(fechaDesde)
            fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();
                
            var deferred = $q.defer();

            $http.get('api/encuestas/results/csv?encuestaId=' + encuestaID + '&aprobados=' + aprobados
            + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&encuestadorId=' + encuestadorId 
            + '&timeZone=' + timeZone, '_blank', '').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function exportResultsToPDF(email, encuestaID, aprobados, fechaDesde, fechaHasta, encuestadorId, timeZone) {
            var deferred = $q.defer();

            if(!aprobados)
                aprobados = false;

            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            $http.get(API_URL+ '/api/encuestas/results/pdf?email=' + email + '&encuestaId=' + encuestaID + '&aprobados=' + aprobados
                                + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&encuestadorId=' + encuestadorId 
                                + '&timeZone=' + timeZone, '_blank', '')
            .then(function(response) {
                deferred.resolve(response.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getResultEstadisticasEfectividad(encuestaId, encuestadorId, instancia, fechaDesde, fechaHasta, approveOnly) {
            var deferred = $q.defer();

            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            $http.post('api/encuestas/results/estadisticas/efectividad/encuesta/' + encuestaId
                + '/encuestador/' + encuestadorId
                + '?approveOnly=' + approveOnly
                + '&instancia=' + instancia
                + '&fechaDesde=' + fechaDesde
                + '&fechaHasta=' + fechaHasta).then(function (response) {
                deferred.resolve(response);
            }).catch(function(error){
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getCriteriosAceptacion () {
            var deferred = $q.defer();

            $http.get('api/encuestas/criteriosaceptacion').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function(error){
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getImage(contactoId, type) {
            var deferred = $q.defer();

            $http.get('api/encuestas/results/contacto/' + contactoId + '/type/' + type).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function sendMediaEmail(address, encuestaId, encuestadorId, fechaDesde, fechaHasta) {
            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();
                
            var deferred = $q.defer();

            $http.get('api/encuestas/results/zip?direccion=' + address + '&encuestaId=' + encuestaId + (fechaDesde ? ("&fechaDesde=" + fechaDesde) : "") + (fechaHasta ? ("&fechaHasta=" + fechaHasta) : "") + (encuestadorId ? ("&encuestadorId=" + encuestadorId) : "")).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function checkForFile(dictId) {
            var deferred = $q.defer();
            $http.get('api/encuestas/results/checkFile?dictId=' + dictId).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getLinkForFile(dictId, esCSV) {
            if(esCSV) {
                return API_URL + '/api/encuestas/results/downloadCSV?dictId=' + dictId
            }
            else {
                return API_URL + '/api/encuestas/results/downloadZip?dictId=' + dictId
            }
        }

        function buscarGeosTodos(encuestaId, fechaDesde, fechaHasta) {
            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            var deferred = $q.defer();

            $http.get('api/encuestas/results/buscarGeosTodos?encuestaId=' + encuestaId + "&fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getEncuestaBySitiowebId(sitiowebId) {
            var deferred = $q.defer();

            $http.get('api/encuestas/results/getEncuestaBySitiowebId?encuestaId=' + sitiowebId).then(function(response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getMediaForCarousel(encuestaId, slides, offset, encuestadorId, fechaDesde, fechaHasta) {
            if (!offset) {
                offset = 0;
            }

            if(fechaDesde)
                fechaDesde = fechaDesde.getTime();

            if(fechaHasta)
                fechaHasta = fechaHasta.getTime();

            var deferred = $q.defer();

            $http.get('api/encuestas/results/getMediaForCarousel?encuestaId=' + encuestaId + '&numberOfSlides=' + slides + '&offset=' + offset + '&encuestadorId=' + encuestadorId + "&fechaDesde=" + fechaDesde + "&fechaHasta=" + fechaHasta).then(function(response) {
                deferred.resolve(response.data);
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
    }
})();