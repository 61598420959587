(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('cotizador', {
            templateUrl: 'app/components/cotizador/cotizador.html',
            controller: 'CotizadorController',
            controllerAs: 'vm',
            bindings: {
                encuesta: '=',
                floatCotizador: '<'
            }
        });
})();
