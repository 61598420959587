(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CotizadorController', CotizadorController);

    CotizadorController.$inject = ['$scope', 'PreguntaEncuestaClienteService', 'MembresiaService',
        'CarritoService', 'CotizadorService', '$timeout'];

    function CotizadorController ($scope, PreguntaEncuestaClienteService,
                                  MembresiaService, CarritoService, CotizadorService, $timeout) {
        var vm = this;

        function init() {
            vm.finalPrice = 0;
            vm.sliderDisabled = false;
            vm.accesoComunidad = 0;
            vm.totalPrice = 0;
            vm.costoContacto = 0;
            vm.contactos.precioUnitario = 250;

            vm.precioSimple = 0;
            vm.precioAudioVideo = 0;
            vm.precioImagen = 0;

            CarritoService.getCotizadorVariable('ACCESO_COMUNIDAD').then(function (response) {
                vm.accesoComunidad = vm.hasMembership ? 0 : response.data.Valor;
            });

            if(!vm.encuesta){
                vm.encuesta = {};
                vm.encuesta.Preguntas = [];
            }

            vm.encuesta.totalQuestions = {};
            vm.encuesta.totalQuestions.cantidad = 0;

            vm.encuesta.simpleQuestions = {
                cantidad: 0,
                precioUnitario: 10,
                options: {
                    floor: 0,
                    ceil: 30,
                    showSelectionBar: true,
                    selectionBarGradient: {
                        from: '#b0b3d6',
                        to: '#001b56'
                    },
                    onChange: function(){
                        vm.calculatePrice();
                    },
                },
            };

            vm.encuesta.audioVideoQuestions = {
                cantidad: 0,
                precioUnitario: 50,
                options: {
                    floor: 0,
                    ceil: 30,
                    showSelectionBar: true,
                    selectionBarGradient: {
                        from: '#b0b3d6',
                        to: '#001b56'
                    },
                    onChange: function(){
                        vm.calculatePrice()
                    },
                },
            };

            vm.encuesta.imageQuestions = {
                cantidad: 0,
                precioUnitario: 30,
                options: {
                    floor: 0,
                    ceil: 30,
                    showSelectionBar: true,
                    selectionBarGradient: {
                        from: '#b0b3d6',
                        to: '#001b56'
                    },
                    onChange: function(){
                        vm.calculatePrice()
                    },
                },
            };

            MembresiaService.getUserMembership().then(function(result){
                vm.hasMembership = result;
                if(!vm.hasMembership){
                    vm.totalPrice = 3500;
                }
            });

            PreguntaEncuestaClienteService.queryQuestionsTypes().then(function(result){
                vm.questionsTypes = result;
                angular.forEach(vm.questionsTypes, function (type){
                    if(type.Nombre === 'NUMERICO'){
                        vm.precioSimple = type.Precio;
                    }
                    if(type.Nombre === 'AUDIO'){
                        vm.precioAudioVideo = type.Precio;
                    }
                    if(type.Nombre === 'IMAGEN'){
                        vm.precioImagen = type.Precio;
                    }
                });
            });

            if(!vm.floatCotizador){
                vm.encuesta.audioVideoQuestions.options.readOnly = true;
                vm.encuesta.imageQuestions.options.readOnly = true;
                vm.encuesta.simpleQuestions.options.readOnly = true;
            }
        }

        $scope.$watch('vm.encuesta.CantidadContactos', function() {
            if(vm.encuesta.CantidadContactos){
                vm.contactos.cantidad = vm.encuesta.CantidadContactos;
                vm.calculatePrice();
            }
        });
        $scope.$watch('vm.contactos.cantidad', function() {
            vm.encuesta.CantidadContactos = vm.contactos.cantidad;
            vm.calculatePrice();
        });
        $scope.$watch('vm.encuesta.totalQuestions.cantidad', function() {
            vm.calculatePrice();
        });

        vm.contactos = {
            cantidad: 0,
            options: {
                showSelectionBar: true,
                ceil: 3000,
                selectionBarGradient: {
                    from: '#b0b3d6',
                    to: '#001b56'
                },
                onChange: function(){
                    vm.calculatePrice()
                },
                showTicks: 500
            }
        };

        vm.contactTypes = [
            'Auditor',
            'Consumidor'
        ];

        //refresca el slider al modificar un input.
        vm.refreshSlider = function() {
            $scope.$broadcast('rzSliderForceRender');
        };

        //Calcula el precio para cada pregunta segun tipo y cantidad.
        vm.calculatePrice = function (){

            if(vm.floatCotizador){
                vm.encuesta.Preguntas = [];

                for(var i = 0; i < vm.encuesta.simpleQuestions.cantidad; i++){
                    var pregunta = {
                        TipoPregunta: vm.questionsTypes[0]
                    };
                    vm.encuesta.Preguntas.push(pregunta);
                };
                for(var i = 0; i < vm.encuesta.audioVideoQuestions.cantidad; i++){
                    var pregunta = {
                        TipoPregunta: vm.questionsTypes[8]
                    };
                    vm.encuesta.Preguntas.push(pregunta);
                };
                for(var i = 0; i < vm.encuesta.imageQuestions.cantidad; i++){
                    var pregunta = {
                        TipoPregunta: vm.questionsTypes[4]
                    };
                    vm.encuesta.Preguntas.push(pregunta);
                };
            }

            if(vm.encuesta.Preguntas){
                vm.totalPrice = vm.getTotalPrecio();
                vm.encuesta.Monto = vm.totalPrice;
            }

            vm.bonificado = vm.getPorcentajeBonificacion(vm.getTotal()) * 100;
            vm.costoContacto = vm.getTotalContactos() > 0 ? (vm.getTotalPrecio() - vm.accesoComunidad) / vm.getTotalContactos() : 0;

            if(vm.hasMembership){
                vm.accesoComunidad = 0;
            } else {
                vm.accesoComunidad = 3500;
            }
            vm.contactos.price = vm.contactos.precioUnitario * vm.contactos.cantidad;

            vm.encuesta.totalQuestions.cantidad = vm.encuesta.simpleQuestions.cantidad + vm.encuesta.audioVideoQuestions.cantidad + vm.encuesta.imageQuestions.cantidad;
        };

        vm.getContactosBonificados = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.min(encuesta.CantidadContactos, vm.cantidadContactos);
            }, 0);
        };

        vm.getContactosBonificadosTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.min(encuesta.CantidadContactos, vm.cantidadContactos) * encuesta.precioContacto;
            }, 0);
        };

        vm.getPreguntasNormalesTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.max(encuesta.Preguntas.length - vm.cantidadPreguntas, 0);
            }, 0);
        };

        vm.getTotalContactos = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + encuesta.CantidadContactos;
            }, 0);
        };

        vm.getPrecioPreguntasNormalesTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                var exPreguntas = Math.max(encuesta.Preguntas.length-vm.cantidadPreguntas, 0);
                var totalPreguntas = encuesta.Preguntas
                    .map(function (pregunta) {
                        return pregunta.TipoPregunta.Precio;
                    })
                    .sort()
                    .reverse()
                    .slice(0, exPreguntas)
                    .reduce(function (n1, x) {
                        return n1+x;
                    }, 0);

                return n + totalPreguntas;
            }, 0);
        };

        vm.getSubtotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + encuesta.CantidadContactos * encuesta.precioContacto;
            }, 0);
        };

        vm.getTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + CotizadorService.getPrecioEncuesta(encuesta);
            }, 0);
        };

        vm.getPorcentajeBonificacion = function (total) {
            return CotizadorService.getBonificacion(total);
        };

        vm.getTotalPrecio = function () {
            if(!vm.encuestas){
                vm.encuestas = [vm.encuesta];
            }
            return CotizadorService.getTotal(vm.encuestas, vm.hasMembership, vm.floatCotizador);
        };

        init();
    }
})();
