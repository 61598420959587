(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CarritoEncuestaClienteController', CarritoEncuestaClienteController);

    CarritoEncuestaClienteController.$inject = ['$rootScope', 'CarritoProvider',
        'EncuestasClienteService', '$mdDialog', '$state'];

    function CarritoEncuestaClienteController ($rootScope, CarritoProvider, EncuestasClienteService,
                                               $mdDialog, $state, $stateParams) {
    var vm = this;
    vm.changeTab = changeTab;
    vm.saveAndAddToCart = saveAndAddToCart;
    vm.completa = completa;
    vm.save = save;
    vm.floatCotizador = false;
    vm.listaBoolean = [{ Valor: false}];
    vm.myVar = false;

    vm.faltaGuardar = "Los datos de la encuesta son obligatorios para poder guardar";
    vm.faltaAgregar = "Es necesario que la Encuesta tenga al menos una pregunta, una ubicación definida y cantidad de contactos mayor a 0 para poder agregarla al carrito.";

    function completa() {
        angular.forEach(vm.preguntas, function(pregunta, index){
            vm.listaBoolean[index] = false;
        });
    }

    function changeTab() {
        $('html, body').animate({scrollTop:0}, 'slow');
        $rootScope.changeTabSig();
    }

    vm.showAlertOnSave = function(ev) {
        // Appending dialog to document.body to cover sidenav in docs app
        var confirm = $mdDialog.confirm()
            .title('Encuesta guardada.')
            .textContent('La encuesta se ha guardado correctamente.')
            .ariaLabel('')
            .targetEvent(ev)
            .ok('Cerrar')
            .cancel('Ir a encuestas');

        $mdDialog.show(confirm).then(function() {
        }, function() {
            $state.go('encuestasCliente');
        });
    };

        vm.showAlertOnAddToCart = function(ev) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.confirm()
                .title('Encuesta guardada.')
                .textContent('La encuesta se ha agregado al carrito correctamente.')
                .ariaLabel('')
                .targetEvent(ev)
                .ok('Ir al carrito')
                .cancel('Ir a encuestas');
            $mdDialog.show(confirm).then(function() {
                $state.go('carrito');
            }, function() {
                $state.go('encuestasCliente');
            });
        };

    function save($event){
        vm.isSaving = true;
        angular.forEach(vm.encuesta.Ubicaciones, function (ubicacion) {
            ubicacion.Latitud = ubicacion.Pos[0];
            ubicacion.Longitud = ubicacion.Pos[1];
        });

        var i = 0;
        angular.forEach(vm.encuesta.Preguntas, function(pregunta) {
            pregunta.Orden = i;
            if (pregunta.PreguntaPadre == 0 || vm.encuesta.Preguntas[i - 1].TipoPregunta.Nombre != 'SI/NO' || 
            vm.encuesta.Preguntas[i - 1].TipoPregunta.Nombre != 'OPCION_MULTIPLE' || 
            (vm.encuesta.Preguntas[i - 1].TipoPregunta.Nombre == 'OPCION_MULTIPLE' && pregunta.RequeridoOpcionMultiple == 0))
                pregunta.RequeridaPorPadre = null;
            ++i;
            if (pregunta.TipoPregunta.Nombre != 'NUMERICO' || !pregunta.Preventivo)
                pregunta.ControlPreventivo = null;
        });
        
        if(vm.encuesta.isDuplicated == true){
            vm.encuesta.Id = null;
            vm.encuesta.EncuestaSitioWebId = 0;
        }

        EncuestasClienteService.save(vm.encuesta).then(function (response) {
            vm.encuesta = response;
            vm.showAlertOnSave($event);
        });
    }

    function saveAndAddToCart($event) {
        angular.forEach(vm.encuesta.Ubicaciones, function(ubicacion){
            ubicacion.Latitud = ubicacion.Pos[0];
            ubicacion.Longitud = ubicacion.Pos[1];
        });

        for (var i=0;i<vm.encuesta.Preguntas.length;i++) {
            vm.encuesta.Preguntas[i].Orden = i;
        }
        
        console.log('vm.encuesta.isDuplicated', vm.encuesta.isDuplicated);
        if(vm.encuesta.isDuplicated == true){
            vm.encuesta.Id = null;
            vm.encuesta.EncuestaSitioWebId = 0;
            console.log('vm.encuesta.isDuplicated', vm.encuesta.isDuplicated);   
        }

        CarritoProvider.addEncuesta(vm.encuesta);
        vm.showAlertOnAddToCart($event);
    }
}})();
