(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$mdDialog', '$interval',
        'auditoresActivos', 'clientes', 'encuestaContactos', 'chartVariables'];

    function HomeController ($scope, Principal, LoginService, $state, $mdDialog, $interval,
                             auditoresActivos, clientes, encuestaContactos, chartVariables) {
        var vm = this;

        // Asingacion de variables
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.login = login;
        vm.auditoresActivos = auditoresActivos;
        vm.clientes = clientes;
        vm.encuestaContactos = encuestaContactos;
        vm.resenas;

        vm.fechas = [];
        vm.contactos = [];

        angular.forEach(vm.encuestaContactos, function(contacto){
           contacto.formatDate = moment(contacto.Fecha).format('D/MMM');
           vm.fechas.push(contacto.formatDate);
           vm.contactos.push(contacto.Cantidad);
        });

        // Asingacion de funciones
        vm.register = register;
        vm.changeVideo = changeVideo;


        // Lllamado de funciones
        init();
        getAccount();

        function login() {
            LoginService.open();
        }
        // Definicion de funciones
        function init() {
            $scope.$on('authenticationSuccess', function() {
                getAccount();
            });

            if($state.params.openLogin){
                vm.login();
            }

            $(document).ready(function () {
                $("#myModal").on("hidden.bs.modal", function () {
                    $("#iframeYoutube").attr("src", "#");
                })
            });
        }

         //animation
         $interval(function(){
            $('#realtimeAuditors').fadeOut(1500);
            $('#realtimeClients').fadeOut(1500);

            $('#realtimeAuditors').fadeIn(1500);
            $('#realtimeClients').fadeIn(1500);
         }, 3000);

        vm.showVideo = function(ev) {
            $mdDialog.show({
                controller: ['$mdDialog', function DialogController($mdDialog) {
                    var vm = this;
                    vm.closeModal = function() {
                        $mdDialog.hide();
                    }
                }],
                template: '<div>' +
                '<i ng-click="vm.closeModal()" class="fas fa-times" style="position: absolute; top: 10px; right: 10px;"></i>' +
                '<iframe style="width: 768px; height: 434px;" src="https://www.youtube.com/embed/cuB5P6pvbeY?rel=0&amp;controls=0&amp;showinfo=0?ecver=1">' +
                '</iframe>' +
                '</div>',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                controllerAs:'vm',
            });
        };

        vm.closeModal = function() {
            $mdDialog.hide();
        };

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated();
            });
        }

        function register () {
            $state.go('register');
        }

        function changeVideo() {
            var iframe = document.getElementById("iframeYoutube");
            iframe.src = "https://www.youtube.com/embed/FN--MpnF1EY?rel=0&amp;controls=0&amp;showinfo=0?ecver=1";

            $("#myModal").modal("show");
        }
    }
})();
