(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CarritoCompraDialogController', CarritoCompraDialogController);

    CarritoCompraDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance',
                                            'encuestas', 'CarritoService', 'precioTotal', 'CarritoProvider', '$http', 'lastFactura', 'membresia'];

    // TODO: Use Stripe.JS library and validation elements 6/25/2018
    function CarritoCompraDialogController ($scope, $stateParams, $uibModalInstance,
                                            encuestas, CarritoService, precioTotal, CarritoProvider, $http, lastFactura, membresia) {
        var vm = this;

        function init() {
            vm.card = {
                name: "APPROVED",
                number: "4268 0700 0000 0002",
                expiredDate: "01/20",
                CSV: "123"
            };
            vm.encuestas = encuestas;
            vm.montoTotal = precioTotal;
            vm.processingPayment = false;
            vm.payDone = false;
            vm.payError = false;
            vm.factura = false;
            vm.facturaInfo = lastFactura.data;
            vm.tipoPago = 1;

            vm.CLABE = "";
            vm.trazabilityCode = "";
            vm.isPayPalRendered = false;
        }

        vm.cambio = function () {
            if (vm.tipoPago == 2) {
                vm.isPayPalRendered = false;
            }
            if (vm.tipoPago == 3) {
                vm.isPayPalRendered = false;
            }
        }

        vm.checkout = function () {
            vm.processingPayment = true;

            if (vm.tipoPago == 1) {
                vm.pagoTarjeta();
            }
            if (vm.tipoPago == 2) {
                vm.pagoTransferencia();
            }
            if (vm.tipoPago == 3) {
                vm.isPayPalRendered = false;
                vm.pagoCorporativo();
            }
        };

        vm.pagoCorporativo = function () {
            var payment = {
                EncuestaDTOs: vm.encuestas,
                Monto: vm.montoTotal,
                Type: "CORP",
                Factura: vm.factura,
                Nombre: vm.facturaInfo.Nombre,
                Apellido: vm.facturaInfo.Apellido,
                Direccion: vm.facturaInfo.Direccion,
                Email: vm.facturaInfo.Email,
                RFC: vm.facturaInfo.RFC,
                membresiaId: membresia.Id
            };
            CarritoService.paySurveys(payment).then(function(response){
                vm.processingPayment = false;
                if(response.Estado !== 'SUCCESS'){
                    vm.payError = true;
                }else {
                    vm.payDone = true;
                    CarritoProvider.fetchEncuestasCarrito();
                }
            }, function() {
                vm.processingPayment = false;
                vm.payError = true;
            });
        };

        vm.pagoTransferencia = function () {
            var payment = {
                EncuestaDTOs: vm.encuestas,
                Monto: vm.montoTotal,
                Type: "BANK",
                Factura: vm.factura,
                Nombre: vm.facturaInfo.Nombre,
                Apellido: vm.facturaInfo.Apellido,
                Direccion: vm.facturaInfo.Direccion,
                Email: vm.facturaInfo.Email,
                RFC: vm.facturaInfo.RFC
            };
            CarritoService.paySurveys(payment).then(function(response){
                vm.processingPayment = false;
                if(response.Estado != 'SUCCESS'){
                    vm.payError = true;
                } else {
                    vm.CLABE = response.Referencia;
                    vm.Montos = response.Montos;

                    vm.payDone = true;
                    CarritoProvider.fetchEncuestasCarrito();
                }
            }, function() {
                vm.processingPayment = false;
                vm.payError = true;
            });
        };

        vm.pagoTarjeta = function () {
            if(!vm.isPayPalRendered) {
                paypal.Buttons({
                    createOrder: function(data, actions) {
                        // Set up the transaction
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: vm.montoTotal
                                }
                            }]
                        });
                    },
                    onApprove: function(data, actions) {
                        // Capture the funds from the transaction
                        return actions.order.capture().then(function(details) {

                            var payment = {
                                        EncuestaDTOs: vm.encuestas,
                                        Monto: vm.montoTotal,
                                        Type: "CARD",
                                        Factura: vm.factura,
                                        Nombre: vm.facturaInfo.Nombre,
                                        Apellido: vm.facturaInfo.Apellido,
                                        Direccion: vm.facturaInfo.Direccion,
                                        Email: vm.facturaInfo.Email,
                                        RFC: vm.facturaInfo.RFC,
                                        OrderId: data.orderID
                            };

                            CarritoService.paySurveys(payment).then(function(response){
                                vm.processingPayment = false;
                                if(response.Estado !== 'SUCCESS'){
                                    vm.payError = true;
                                }else {
                                    vm.payDone = true;
                                    CarritoProvider.fetchEncuestasCarrito();
                                }
                            }, function() {
                                vm.processingPayment = false;
                                vm.payError = true;
                            });

                        });
                    }
                }).render('#paypal-button-container');

                vm.isPayPalRendered = true;
            }
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        init();
    }
})();