(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .directive('ngFiles', ['$parse', function ($parse) {

            function fn_link(scope, element, attrs) {
                var onChange = $parse(attrs.ngFiles);
                element.on('change', function (event) {
                    onChange(scope, { $files: event.target.files });
                });
            };

            return {
                link: fn_link
            }
        } ])
        .controller('AdminEncuestasClienteController', AdminEncuestasClienteController);

    AdminEncuestasClienteController.$inject = ['encuestas', '$uibModal', '$state', '$scope', '$http', 'EncuestasClienteService'];

    function AdminEncuestasClienteController (encuestas, $uibModal, $state, $scope, $http, EncuestasClienteService) {
        var vm = this;

        var formdata;

        function init () {
            window.scrollTo(0, 0);
            vm.encuestas = encuestas;
            formdata = new FormData();
            vm.isBlankFile = true;
        }

        vm.aprobarEncuesta = function(encuesta) {
            $uibModal.open({
                templateUrl: 'app/admin/encuestasCliente/aprobarEncuestaCliente-dialog.html',
                controller: 'AprobarEncuestaDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    encuesta: encuesta
                }
            }).result.then(function() {
                $state.reload();
            });
        };

        init();

        $scope.getTheFiles = function ($files) {
            vm.isBlankFile = false;
            angular.forEach($files, function (value) {
                formdata.append('file', value);
            });
        };

        $scope.uploadFiles = function () {
            vm.isProcessing = true;
            console.log('form', formdata);            
            // NOW UPLOAD THE FILES.
                var request = {
                    method: 'POST',
                    url: 'api/encuestas/create-from-excel',
                    data: formdata,
                    headers: {
                        'Content-Type': undefined
                    }
                };

                // SEND THE FILES.
                $http(request)
                    .success(function (result) {
                        vm.isProcessing = false;
                        console.log("success", result);
                    })
                    .error(function (result) {
                        vm.isProcessing = false;
                        console.log("error!", result);
                    });
            
        }

    }
})();
