(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('HomeService', HomeService);

    HomeService.$inject = ['$q', '$http', 'BACKOFFICE_API_URL'];

    function HomeService ($q, $http, BACKOFFICE_API_URL) {
        return {
            queryAuditores: queryAuditores,
            queryClientes: queryClientes,
            queryEncuestaContactos: queryEncuestaContactos,
            queryDashboardVariables: queryDashboardVariables
        };

        function queryAuditores() {
            var deferred = $q.defer();

            $http.get('api/getAuditoresActivos').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryEncuestaContactos () {
            // var deferred = $q.defer();

            // $http.get(BACKOFFICE_API_URL + '/newapi/getestadisticas?limite=60').then(function (response) {
            //     deferred.resolve(response.data);
            // }).catch(function (error) {
            //     deferred.reject(error);
            // });

            // return deferred.promise;
        }

        function queryClientes() {
            var deferred = $q.defer();

            $http.get('api/getClientes').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryDashboardVariables(count) {
            var deferred = $q.defer();

            $http.get('api/getChart/'+count).then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
    }
})();