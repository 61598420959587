(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CarritoRemoveEncuestaController', CarritoRemoveEncuestaController);

    CarritoRemoveEncuestaController.$inject = ['CarritoProvider', 'encuesta', '$uibModalInstance'];

    function CarritoRemoveEncuestaController (CarritoProvider, encuesta, $uibModalInstance) {
        var vm = this;

        function init () {
            vm.encuesta = encuesta;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.remove = function() {
            CarritoProvider.removeEncuesta(vm.encuesta);
            $uibModalInstance.close(vm.encuesta);
        };

        init();
    }
})();
