(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('UbicacionEncuestaClienteController', UbicacionEncuestaClienteController);

    UbicacionEncuestaClienteController.$inject = ['$rootScope', 'NgMap', '$timeout', '$uibModal',
        'UbicacionEncuestaService','UbicacionEncuestaClienteLocationsService', '$mdDialog', '$scope', '$q'];

    function UbicacionEncuestaClienteController($rootScope, NgMap, $timeout, $uibModal,
                                                UbicacionEncuestaService, UbicacionEncuestaClienteLocationsService, $mdDialog, $scope, $q) {
        var vm = this;

        vm.validateForm = validateForm;
        vm.confirmEncuesta = confirmEncuesta;
        vm.exportTableToExcel = exportTableToExcel;
        vm.cargarTabla = cargarTabla;
        vm.descargarTabla = descargarTabla;
        vm.descargarTemplete = descargarTemplete;
        vm.topPage = topPage;

        vm.isLoading = false;
        vm.encuesta.Ubicaciones = [];
        vm.encuesta.CantidadContactos = 0;
        vm.mostrarTabla = false;
        //Setea la posicion del mapa al inciarlo, en Mexico.
        vm.center = [19.41, -99.14];

        vm.arregloExcel = [{
            Canal: '',
            Cadena: '',
            Formato: '',
            Tienda: '',
            CodigoTienda: '',
            Latitud: '',
            Longitud: '',
            Calle: '',
            Estado: '',
            Municipio: '',
            DescTRM: ''
        }];

        vm.templete = [{
            Canal: '',
            Cadena: '',
            Formato: '',
            Tienda: '',
            CodigoTienda: '',
            Latitud: '',
            Longitud: '',
            Calle: '',
            Estado: '',
            Municipio: '',
            DescTRM: ''
        }];

        $scope.$watch('vm.encuesta.Ubicaciones', function () {
            NgMap.getMap().then(function (map) {
                vm.map = map;
            });
        });

        NgMap.getMap().then(function (map) {
            vm.map = map;
        });

        vm.zoomOut = function () {
            if (window.matchMedia("(max-width: 767px)").matches) {
                vm.map.setZoom(4);
                vm.map.setCenter(new google.maps.LatLng(19.41, -99.14));
            }
            else {
                vm.map.setZoom(5);
                vm.map.setCenter(new google.maps.LatLng(19.41, -99.14));
            }
        };

        vm.getpos = function (event) {
            vm.lat = event.latLng.lat();
            vm.lng = event.latLng.lng();
            vm.latlng = [event.latLng.lat(), event.latLng.lng()];
        };


        vm.addMarker = function (event) {
            var ll = event.latLng;
            var lat = ll.lat();
            var lng = ll.lng();

            vm.getReverseGeocodingData(lat, lng);
        };

        vm.placeChanged = function () {
            vm.place = this.getPlace();
        };

        vm.addLocationToList = function () {
            var isPlace = false;
            var nuevaUbicacion = {
                Pos: [vm.place.geometry.location.lat(), vm.place.geometry.location.lng()],
                Nombre: vm.address,
                RadioCobertura: 1
            }

            vm.center = nuevaUbicacion.Pos;
            angular.forEach(vm.place.types, function (type) {
                if (!isPlace) {
                    if (type === 'locality' || type === 'political') {
                        nuevaUbicacion.Tipo = 'state';
                        nuevaUbicacion.CantidadContactos = 0;
                        isPlace = true;
                    } else {
                        nuevaUbicacion.CantidadContactos = 1;
                        nuevaUbicacion.Tipo = 'place';
                    }
                }
            });
            if (nuevaUbicacion.Tipo === 'place') {
                vm.encuesta.CantidadContactos += 1;
            }

            vm.encuesta.Ubicaciones.push(nuevaUbicacion);
            vm.cargarTabla();
            NgMap.getMap().then(function (map) {
                vm.map = map;
            });
            vm.address = null;
        };

        //Funcion que regresa dirección cuando recibe como parámetros latitud y longitud
        vm.getReverseGeocodingData = function (lat, lng) {
            var exit = false;
            var markerData;
            var latlng = new google.maps.LatLng(lat, lng);
            var posString = [lat, lng];
            // Aca se obtiene la direccion.
            var geocoder = new google.maps.Geocoder();

            geocoder.geocode({'latLng': latlng}, function (results, status) {
                if (status !== google.maps.GeocoderStatus.OK) {
                    console.log("ok");
                    $timeout(function () {
                        vm.getReverseGeocodingData(lat, lng);
                    }, 300);
                    exit = true;
                }

                if (!exit) {
                    var isPlace = false;
                    // This is checking to see if the Geoeode Status is OK before proceeding
                    if (status == google.maps.GeocoderStatus.OK) {
                        results[0].formatted_address;
                        var address = (results[0].formatted_address);
                        markerData = {
                            "Nombre": address,
                            "Pos": posString,
                            "NombreLista": '',
                            "Estado": '',
                            "RadioCobertura": 1
                        }

                        angular.forEach(results[0].types, function (type) {
                            if (!isPlace) {
                                if (type === 'locality' || type === 'political') {
                                    markerData.Tipo = 'state';
                                    isPlace = true;
                                } else {
                                    markerData.Tipo = 'place';
                                }
                            }
                        });
                        if (markerData.Tipo === 'place') {
                            markerData.CantidadContactos = 1;
                            vm.encuesta.CantidadContactos += 1;
                        }

                        vm.encuesta.Ubicaciones.push(markerData);
                    }

                    if (vm.encuesta.Ubicaciones.length > 0) {
                        vm.isAddressEmpty = false;
                    }

                    NgMap.getMap().then(function (map) {
                        vm.map = map;
                    });
                    cargarTabla();
                }
            });
        };

        vm.showDeleteConfirmation = function (ev) {
            // Modal para borrar toda la lista.
            var confirm = $mdDialog.confirm()
                .title('¿Desea borrar todas las localidades de la lista?')
                .ok('Si')
                .cancel('Cancelar');

            $mdDialog.show(confirm).then(function () {
                vm.encuesta.Ubicaciones = [];
            }, function () {

            });
        };

        var isDefaultList = false;
        vm.showDefaultLists = function () {
            isDefaultList = true;
            showLocationLists();
        };

        vm.showUserLists = function () {
            isDefaultList = false;
            showLocationLists();
        };

        vm.setContacts = function () {
            vm.encuesta.CantidadContactos = 0;
            angular.forEach(vm.encuesta.Ubicaciones, function (ubicacion) {
                vm.encuesta.CantidadContactos += ubicacion.CantidadContactos;
            });
        };

        var showLocationLists = function () {
            $uibModal.open({
                templateUrl: 'app/components/ubicacionEncuestaCliente/ubicacionEncuestaCliente-locations.html',
                controller: 'UbicacionEncuestaClienteLocationsController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    isDefault: isDefaultList,
                    defaultList: ['UbicacionEncuestaService', function (UbicacionEncuestaService) {
                        var list;
                        if (isDefaultList) {
                            list = UbicacionEncuestaService.queryDefaultLists();
                        } else {
                            list = UbicacionEncuestaService.queryUserLists();
                        }
                        return list;
                    }],
                    CadenasList: ['UbicacionEncuestaClienteLocationsService', function (UbicacionEncuestaClienteLocationsService) {
                        var deferred = $q.defer();
                        isDefaultList = true;
                        if (isDefaultList) {
                            var lista = UbicacionEncuestaClienteLocationsService.queryCadenasLists();
                            return lista;
                        } else {
                            deferred.resolve(null);
                            return deferred;
                        }
                    }],
                    TiendasList: ['UbicacionEncuestaClienteLocationsService', function (UbicacionEncuestaClienteLocationsService) {
                        var deferred = $q.defer();
                        if (isDefaultList) {
                            var lista = UbicacionEncuestaClienteLocationsService.queryTiendasLists();
                            return lista;
                        } else {
                            deferred.resolve(null);
                        }
                        return deferred;
                    }],
                    EstadosList: ['UbicacionEncuestaClienteLocationsService', function (UbicacionEncuestaClienteLocationsService) {
                        var deferred = $q.defer();
                        if (isDefaultList) {
                            var lista = UbicacionEncuestaClienteLocationsService.queryEstadosLists();
                            return lista;
                        } else {
                            deferred.resolve(null);
                        }
                        return deferred;

                    }],
                    MunicipiosList: ['UbicacionEncuestaClienteLocationsService', function (UbicacionEncuestaClienteLocationsService) {
                        var deferred = $q.defer();
                        if (isDefaultList) {
                            var lista = UbicacionEncuestaClienteLocationsService.queryMunicipiosLists();
                            return lista;
                        } else {
                            deferred.resolve(null);
                        }
                        return deferred;
                    }]
                }
            }).result.then(function (result) {
                vm.encuesta.Ubicaciones.length = 0;
                if (Ubicaciones) {
                    Ubicaciones.length = 0;
                }
                var Ubicaciones = result.Ubicacions;
                vm.encuesta.Ubicaciones.options = {
                    floor: 0,
                    ceil: Ubicaciones.length,
                    showSelectionBar: true,
                    selectionBarGradient: {
                        from: '#b0b3d6',
                        to: '#001b56'
                    }
                };
                vm.maxUbicaciones = Ubicaciones.length;
                vm.isLoading = true;
                angular.forEach(Ubicaciones, function (ubicacion) {
                    ubicacion.Pos = [];
                    ubicacion.Pos[0] = ubicacion.Latitud;
                    ubicacion.Pos[1] = ubicacion.Longitud;
                    vm.getReverseGeocodingData(ubicacion.Latitud, ubicacion.Longitud);
                });
                $scope.$watch('vm.encuesta.Ubicaciones.length', function () {
                    if (vm.encuesta.Ubicaciones.length === vm.maxUbicaciones) {
                        vm.isLoading = false;
                    }
                });
            });
        };

        vm.removeLocation = function (index) {
            vm.encuesta.Ubicaciones.splice(index, 1);
            cargarTabla();
        };

        vm.saveLocationLists = function () {
            $uibModal.open({
                templateUrl: 'app/components/ubicacionEncuestaCliente/ubicacionEncuestaCliente-save-locations-dialog.html',
                controller: 'UbicacionEncuestaClienteSaveLocationsDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    ubicacions: [function () {
                        return vm.encuesta.Ubicaciones;
                    }]
                }
            });
            cargarTabla();
        };

        vm.setDefaultRadio = function () {
            angular.forEach(vm.encuesta.Ubicaciones, function (ubicacion) {
                ubicacion.RadioCobertura = vm.defaultRadioValue;
            });
        };

        vm.borrar = function () {
            if (vm.encuesta.Ubicaciones.length === 0) {
                vm.encuesta.Ubicaciones = [];
            }
        };

        function confirmEncuesta() {
            $('html, body').animate({scrollTop: 0}, 'slow');
            if (vm.validateForm()) {
                $rootScope.changeToFinal();
            }
        };

        function validateForm() {
            return true;
        };

        function exportTableToExcel(){
                vm.cargarTabla();
                vm.descargarTabla();
        }

        function descargarTemplete() {
            var downloadLink;
            var datatype = "text/plain;charset=utf-8;";
            var tableSelect = document.getElementById('downloadTemplete');
            var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
            // Specify file name
            var filename = 'ubicaciones';
            filename = filename?filename+'.xls':'excel_data.xls';
            // Create download link element
            downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            if(navigator.msSaveOrOpenBlob){
                var blob = new Blob(['\ufeff', tableHTML], {
                    type: datatype
                });
                navigator.msSaveOrOpenBlob( blob, filename);
            }else{
                // Create a link to the file
                downloadLink.href = 'data:' + datatype + ', ' + tableHTML;
                // Setting the file name
                downloadLink.download = filename;
                //triggering the function
                downloadLink.click();
            }
        }

        function descargarTabla(){
            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect = document.getElementById('exportable');

            var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            // Specify file name
            var filename = 'ubicaciones';
            filename = filename?filename+'.xls':'excel_data.xls';

            // Create download link element
            downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if(navigator.msSaveOrOpenBlob){
                var blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob( blob, filename);
            }else{
                // Create a link to the file
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }
        }

        function cargarTabla() {
            vm.arregloExcel = [];
            angular.forEach(vm.encuesta.Ubicaciones, function(value, key){
                vm.arregloExcel.push({
                    Canal: '',
                    Cadena: '',
                    Formato: '',
                    Tienda: value.Nombre,
                    CodigoTienda: '',
                    Latitud: value.Pos[0],
                    Longitud: value.Pos[1],
                    Calle: '',
                    Estado: '',
                    Municipio: '',
                    DescTRM: ''
                });
            });
        }

        function topPage() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }      
})();
