(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('AprobarEncuestaDialogController', AprobarEncuestaDialogController);

    AprobarEncuestaDialogController.$inject = ['$uibModalInstance', 'encuesta', 'EncuestasClienteService'];

    function AprobarEncuestaDialogController ($uibModalInstance, encuesta, EncuestasClienteService) {
        var vm = this;

        function init() {
            vm.encuesta = encuesta;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        vm.aprobar = function() {
            EncuestasClienteService.approve(vm.encuesta.Id).then(function (result) {
                $uibModalInstance.close(result);
            });
        };

        init();
    }
})();