(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('membresiaSaldo', {
            templateUrl: 'app/components/membresiaSaldo/membresiaSaldo.html',
            controller: ['MembresiaService', function (MembresiaService) {
                var vm = this;

                if(!vm.membership){
                    vm.membership = {}
                    vm.membership.CantidadContacto = 0;
                }

                vm.contactosChartData = [
                    vm.contactosLibres,
                    vm.membership.CantidadContacto - vm.contactosLibres
                ];

                vm.doughnutChartOptions = {
                    aspectRatio: 1,
                    hover: {
                        mode: null
                    },
                    tooltips: {
                        enabled: false
                    }
                };

                vm.doughnutChartColors = [
                    '#8bc24a',
                    '#d9e4eb'
                ];

            }],
            controllerAs: 'vm',
            bindings: {
                membership: '<',
                contactosLibres: '<'
            }
        });
})();
