(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ClienteManagementController', ClienteManagementController);

    ClienteManagementController.$inject = ['Cliente', 'ParseLinks', 'AlertService', '$state', 'paginationConstants'];

    function ClienteManagementController(Cliente, ParseLinks, AlertService, $state, paginationConstants) {
        var vm = this;

        function init() {
            vm.page = 1;
            vm.itemsPerPage = paginationConstants.itemsPerPage;
            vm.loadAll();
        }

        vm.loadAll = function() {
            window.scrollTo(0, 0);

            Cliente.query({
                Number: vm.page - 1,
                TotalElements: vm.itemsPerPage
            }, onSuccess, onError);
        };

        function onSuccess (data) {
            vm.totalItems = data.TotalElements;
            vm.queryCount = vm.totalItems;
            vm.page = data.Number + 1;
            vm.clientes = data.Content;
        }

        function onError (error) {
            AlertService.error(error.data.message);
        }

        init();
    }
})();
