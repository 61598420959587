(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CotizadorFlotanteController', CotizadorFlotanteController);

    CotizadorFlotanteController.$inject = [];

    function CotizadorFlotanteController () {
        var vm = this;
        vm.floatCotizador = true;

        vm.openCotizadorFlotante = function () {
            vm.showCotizador = !vm.showCotizador;
            
            if (vm.showCotizador) {
                $("body").addClass("modal-open");
            }
            else {
                $("body").removeClass("modal-open");
            }
        };


        function init() {
            vm.showCotizador = false;
        }

        init();
    }
})();
