(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('AdminMembresiaController', AdminMembresiaController);

    AdminMembresiaController.$inject = ['memberships', 'items', '$uibModal', 'MembresiaService'];

    function AdminMembresiaController (memberships, items, $uibModal, MembresiaService) {
        var vm = this;

        function init () {
            vm.message = '';
            vm.memberships = memberships;
            vm.items = items;
            vm.selected = 0;
            loadMembresias();
            //for (var i=0;i<vm.memberships.length;i++)
            //    initItems(vm.memberships[i]);
        }

        function loadMembresias() {
            MembresiaService.getMembresiaToApprove().then(function (result) {
                console.log(result);
                vm.userMembresias = result;
            });
        }

        vm.approveMembresia = function (index) {
            MembresiaService.approveMembresia(vm.userMembresias[index].Id).then(function (result) {
                if(result){
                    vm.message = "Se aprobo la membresia";
                }else{
                    vm.message = "No se pudo aprobar la membresia";
                }
                loadMembresias();
            });
        };

        vm.cancelMembresia = function (index) {
            MembresiaService.cancelMembresiaToApprove(vm.userMembresias[index].Id).then(function (result) {
                if(result){
                    vm.message = "Se cancelo la membresia";
                }else{
                    vm.message = "No se pudo cancelar la membresia";
                }
                loadMembresias();
            });
        };

        vm.clearMessage = function () {
            vm.message = "";
        };

        function initItems(membership) {
            membership.items = [];
            for (var i=0;i<membership.Items.length;i++)
                membership.items[membership.Items[i].Nombre] = true;
        }

        vm.addMembership = function () {
            MembresiaService.createMembership({ Nombre : "Membresia", Descripcion : "Esta mebresia es nueva", items : [] }).then(function (response) {
                vm.memberships.push(response.data);
            });
        };

        vm.removeMembership = function (i) {
            MembresiaService.deleteMembership(vm.memberships[i]).then(function (response) {
                vm.memberships.splice(i, 1);
            });
        };

        vm.addItem = function () {
            MembresiaService.createItem({ Nombre : "Nuevo item", Descripcion: "Este item es nuevo" }).then(function (response) {
                vm.items.push(response.data);
                vm.editItem(vm.items.length-1);
            })
        };

        vm.removeItem = function (i) {
            MembresiaService.deleteItem(vm.items[i]).then(function () {
                vm.items.splice(i, 1);
                MembresiaService.queryMemberships().then(function (response) {
                    vm.memberships = response;
                })
            });
        };

        vm.editItem = function(i) {
            $uibModal.open({
                templateUrl: 'app/admin/membresia/adminMembresiaEditItem-dialog.html',
                controller: 'AdminMembresiaEditItemController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    item: function () {
                        return vm.items[i];
                    }
                }
            }).result.then(function (data) {
                MembresiaService.updateItem(data).then(function (response) {
                    vm.items[i] = response.data;
                });
                MembresiaService.queryMemberships().then(function (response) {
                    vm.memberships = response;
                })
            });
        };

        vm.editMembership = function (i) {
            $uibModal.open({
                templateUrl: 'app/admin/membresia/adminMembresiaEditMembership-dialog.html',
                controller: 'AdminMembresiaEditMembershipController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    membership: function () {
                        initItems(vm.memberships[i]);
                        return vm.memberships[i];
                    },
                    items: function () {
                        return vm.items;
                    }
                }
            }).result.then(function (data) {
                MembresiaService.updateMembership(data).then(function (response) {
                    vm.memberships[i] = response.data;
                });
            });
        };

        window.scrollTo(0, 0);

        init();
    }
})();
