(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            params: {
                openLogin: false
            },
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm',
                    resolve: {
                        auditoresActivos: ['HomeService', function (HomeService) {
                            return HomeService.queryAuditores();
                        }],
                        clientes: ['HomeService', function (HomeService) {
                            return HomeService.queryClientes();
                        }],
                        encuestaContactos: ['HomeService', function (HomeService) {
                            return HomeService.queryEncuestaContactos();
                        }],
                        chartVariables: ['HomeService', function (HomeService) {
                            return HomeService.queryDashboardVariables(14);
                        }]
                    }
                },
            }
        });
    }
})();
