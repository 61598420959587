(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('whatsappLink', {
            templateUrl: 'app/layouts/whatsappLink/whatsappLink.html',
            controllerAs: 'vm',
            bindings: {
                number: '@',
                open: '='
            }
        });
})();
