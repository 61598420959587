(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('EncuestasClienteController', EncuestasClienteController);

    EncuestasClienteController.$inject = ['encuestas', 'encuestasSummary', '$uibModal', '$state', 'EncuestasClienteService','user'];

    function EncuestasClienteController (encuestas, encuestasSummary, $uibModal, $state, EncuestasClienteService, user) {
        var vm = this;

        function init () {
            window.scrollTo(0, 0);

            if(user.Cliente){
                vm.displayOnly = user.Cliente.DisplayOnlyAuditedContacts;
            }else{
                vm.displayOnly = false;
            }
            vm.page = encuestas.Number;
            vm.totalPages = encuestas.TotalPages;
            vm.encuestas = encuestas.Content;
            vm.encuestasSummary = encuestasSummary;
            vm.dateHoy = new Date();
            vm.loadingTabla = false;
            vm.mostrandoBusquedas = false;

            angular.forEach(vm.encuestas, function(value, key) {
                if(value.Estado.Nombre === 'APROBADA' && value.FechaVigencia <= vm.dateHoy){
                    value.Estado.Nombre = 'FINALIZADA';
                }
            });
            loadSummaryCharts(vm.encuestasSummary);
        }

        vm.changePage = function (page) {
            vm.loadingTabla = true;
            EncuestasClienteService.queryEncuestasClienteByPage(page).then(function (response) {
                vm.page = response.Number;
                vm.totalPages = response.TotalPages;
                vm.encuestas = response.Content;
                vm.loadingTabla = false;
            });
        };

        function loadSummaryCharts (encuestasSummary) {
            vm.doughnutChartOptions = {
                aspectRatio: 1,
                hover: {
                    mode: null
                },
                tooltips: {
                    enabled: false
                }
            };

            vm.doughnutChartColors = [
                '#5c8be6',
                '#d9e4eb'
            ];

            vm.activasChartData = [
                encuestasSummary.Activas,
                encuestasSummary.Total - encuestasSummary.Activas
            ];

            vm.vigentesChartData = [
                encuestasSummary.Vigentes,
                encuestasSummary.Total - encuestasSummary.Vigentes
            ];

            vm.finalizadasChartData = [
                encuestasSummary.Finalizadas,
                encuestasSummary.Total - encuestasSummary.Finalizadas
            ];

            vm.pendientesChartData = [
                encuestasSummary.Pendientes,
                encuestasSummary.Total - encuestasSummary.Pendientes
            ];
        }

        vm.removeEncuesta = function(encuesta) {
            $uibModal.open({
                templateUrl: 'app/components/encuestasCliente/encuestasCliente-delete.html',
                controller: 'EncuestasClienteDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    encuesta: function () {
                        return encuesta;
                    }
                }
            }).result.then(function (result) {
                $state.reload();
            });
        };

        vm.searchEncuesta = function(encuesta, page) {
            vm.encuestas = [];
            vm.loadingTabla = true;
            vm.mostrandoBusquedas = true;

            EncuestasClienteService.queryEncuestasClienteByName(encuesta, page).then(function (response) {
                vm.page = response.Number;
                vm.totalPages = response.TotalPages;
                vm.encuestas = response.Content;
                vm.loadingTabla = false;
                
            });
        }

        vm.resetBusqueda = function() {
            if(vm.mostrandoBusquedas) {
                vm.changePage(0);
                vm.mostrandoBusquedas = false;
            }
        }

        window.scrollTo(0, 0);

        init();
    }
})();
