(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('UbicacionEncuestaClienteLocationsController', UbicacionEncuestaClienteLocationsController);

    UbicacionEncuestaClienteLocationsController.$inject = ['$uibModalInstance', 'defaultList', 'CadenasList', 'TiendasList', 'EstadosList', 'MunicipiosList', 'UbicacionEncuestaClienteLocationsService', 'isDefault'];

    function UbicacionEncuestaClienteLocationsController($uibModalInstance, defaultList, CadenasList, TiendasList, EstadosList, MunicipiosList, UbicacionEncuestaClienteLocationsService, isDefault) {
        var vm = this;
        vm.clear = clear;
        vm.select = select;
        vm.selectUbicaciones = selectUbicaciones;

        vm.listVisible = true;
        vm.isPlaceholder = true;
        vm.selected = '';
        vm.isDefault = isDefault;

        vm.listaSelect = [];
        vm.ubicacionesSelect = [];
        vm.listaDefault = defaultList;
        vm.listasUbi = null;
        vm.selectedCadena = '';
        vm.selectedTiendas = '';
        vm.selectedEstados = '';
        vm.selectedMunicipios = '';


        vm.Cadenas = CadenasList.Cadenas;
        vm.Tiendas = TiendasList.Tiendas;
        vm.Estados = EstadosList.Estados;
        vm.Municipios = MunicipiosList.Municipios;

        console.log(CadenasList);
        console.log(TiendasList);
        console.log(EstadosList);
        console.log(MunicipiosList);

        if(isDefault) {
            if (vm.Cadenas.length === 0) {
                vm.Cadenas = ["NO HAY CADENAS"];
            }
            if (vm.Tiendas.length === 0) {
                vm.Tiendas = ["NO HAY TIENDAS"];
            }
            if (vm.Estados.length === 0) {
                vm.Estados = ["NO HAY ESTADOS"];
            }
            if (vm.Municipios.length === 0) {
                vm.Municipios = ["NO HAY MUNICIPIOS"];
            }
        }

        function select(){
            var filtros = {Cadena: vm.selectedCadena, Tienda: vm.selectedTiendas, Estado:vm.selectedEstados, Municipio: vm.selectedMunicipios};
            UbicacionEncuestaClienteLocationsService.queryListsByFilter(filtros).then(function (result) {
               vm.listasUbi = result;
               angular.forEach(vm.listasUbi, function(value, key){
                   vm.listaSelect.push(false);
               });
            });
        }

        function selectUbicaciones(){
            vm.ubicacionesSelect = [];
            angular.forEach(vm.listaSelect, function(value, key){
                    if(value === true){
                        vm.ubicacionesSelect.push(vm.listasUbi[key]);
                    }
            });
            var list = {
                    Nombre: "Lista Default",
                    Descripcion: null,
                    Ubicacions: vm.ubicacionesSelect,
                    Id: 0,
                    $$hashKey: "object:451"};
            vm.selectList(list);
        }

        vm.selectList = function(list){
            console.log(list);
            $uibModalInstance.close(list)
        };

        function clear () {
            $uibModalInstance.dismiss();
        }
    }
})();