(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('CarritoProvider', CarritoProvider);

    CarritoProvider.$inject = ['CarritoService', '$interval', 'EncuestasClienteService'];

    function CarritoProvider (CarritoService, $interval, EncuestasClienteService) {
        var carritoSubject = new Rx.BehaviorSubject([]);
        var interval = $interval(fetchEncuestasCarrito, 300000);

        fetchEncuestasCarrito();

        return {
            fetchEncuestasCarrito: fetchEncuestasCarrito,
            fetchMembresiaCarrito: fetchMembresiaCarrito,
            getCarritoSubject: getCarritoSubject,
            addEncuesta: addEncuesta,
            addMembresia: addMembresia,
            removeEncuesta: removeEncuesta
        };

        function fetchEncuestasCarrito () {
            CarritoService.queryCarrito().then(function(result){
                carritoSubject.onNext(result);
            });
        }

        function fetchMembresiaCarrito () {
            CarritoService.queryMembresiaCarrito().then(function(result){
                carritoSubject.onNext(result);
            });
        }

        function getCarritoSubject () {
            return carritoSubject;
        }

        function addEncuesta (encuesta) {
            EncuestasClienteService.addToCart(encuesta).then(function(response){
                fetchEncuestasCarrito();
            });
        }

        function addMembresia (encuesta) {
            MembresiaService.addToCart(encuesta).then(function(response){
                fetchMembresiaCarrito();
            });
        }

        function removeEncuesta (encuesta) {
            EncuestasClienteService.removeFromCart(encuesta).then(function(){
                var carrito = carritoSubject.getValue();
                var index = carrito.indexOf(encuesta);
                if (index > -1) {
                    carrito.splice(index, 1);
                    carritoSubject.onNext(carrito);
                }
                fetchEncuestasCarrito();
            });
        }
    }
})();