(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('PreguntaEncuestaClienteController', PreguntaEncuestaClienteController);

    PreguntaEncuestaClienteController.$inject = ['$scope'];

    function PreguntaEncuestaClienteController ($scope) {
        var vm = this;
        vm.topPage = topPage;

        vm.tiposPreguntas = vm.questionsTypes;

        // Definir funciones
        vm.cambiar = function (num1,num2) {
            var objTemp = angular.copy(vm.encuesta.Preguntas[num1]);
            vm.encuesta.Preguntas[num1] = angular.copy(vm.encuesta.Preguntas[num2]);
            vm.encuesta.Preguntas[num2] = objTemp;
        };

        vm.addNewPregunta = function () {
            vm.encuesta.Preguntas.push({ Id: null, Descripcion: '', AccionType: '',
                Orden: '', AccionTypeObject: null, PermitirMultiples: false, PreguntaPadre: 0, 
                Requerida: false, Preventivo: false, ControlPreventivo: { PrecioObjetivo: null, 
                MinAlerta: null, MaxAlerta: null, MinCandado: null, MaxCandado: null }, 
                RequeridaPorPadre: false, RequeridoBooleano: false, RequeridoOpcionMultiple: 0
            });
        };

        vm.addNewRespuesta = function (posicion) {
            if(!vm.encuesta.Preguntas[posicion].Opciones){
                vm.encuesta.Preguntas[posicion].Opciones = [];
            }
            var n = vm.encuesta.Preguntas[posicion].Opciones.length;
            var newItemNo = n != 0 ? vm.encuesta.Preguntas[posicion].Opciones[n-1].NumeroRespuesta + 1 : 1;
            vm.encuesta.Preguntas[posicion].Opciones.push({NumeroRespuesta: newItemNo, Opcion: '' });
        };

        vm.addNewProducto = function (posicion) {
            if(!vm.encuesta.Preguntas[posicion].Productos)
                vm.encuesta.Preguntas[posicion].Productos = [];
            var n = vm.encuesta.Preguntas[posicion].Productos.length;
            var newProductoNo = n != 0 ? vm.encuesta.Preguntas[posicion].Productos[n-1].NumeroProducto + 1 : 1;
            vm.encuesta.Preguntas[posicion].Productos.push({NumeroProducto: newProductoNo, Opcion: '' });
        };

        vm.removeProducto = function (pregunta, producto) {
            vm.encuesta.Preguntas[pregunta].Productos.splice(producto, 1);
        };

        vm.removeRespuesta = function (pregunta, respuesta) {
            vm.encuesta.Preguntas[pregunta].Opciones.splice(respuesta, 1);
        };

        vm.cambiarPreguntaPadre = function (numeroPregunta) {
            let pregunta = vm.encuesta.Preguntas[numeroPregunta - 1];
            let preguntaPadre = numeroPregunta, preguntaAux, loop = false;
            for (preguntaPadre = vm.encuesta.Preguntas[preguntaPadre - 1].PreguntaPadre; 
                 preguntaPadre != 0 && pregunta.PreguntaPadre != 0; 
                preguntaAux = vm.encuesta.Preguntas[preguntaPadre - 1], preguntaPadre = preguntaAux.PreguntaPadre) {
                if (preguntaPadre == numeroPregunta)
                    pregunta.PreguntaPadre = 0;
            }

            if (pregunta.PreguntaPadre != 0 && pregunta.Requerida) {
                for (preguntaPadre = pregunta.PreguntaPadre; preguntaPadre != 0 ; preguntaPadre = pregunta.PreguntaPadre) {
                    pregunta = vm.encuesta.Preguntas[preguntaPadre - 1];
                    pregunta.Requerida = true;
                }
            }
        }

        // retorna true si hubo problemas en el control preventivo
        vm.validateControlPreventivo = function (cp) {
            return vm.validateNullAlerta(cp) || vm.validateNullCandado(cp) || vm.validateRangeAlerta(cp) || vm.validateRangeCandado(cp) || vm.validateRanges(cp);
        }

        vm.validateNullAlerta = function (cp) {
            return (cp.MinAlerta != null && cp.MaxAlerta == null) || (cp.MaxAlerta != null && cp.MinAlerta == null);
        }

        vm.validateNullCandado = function (cp) {
            return (cp.MinCandado != null && cp.MaxCandado == null) || (cp.MaxCandado != null && cp.MinCandado == null)
        }

        vm.validateRangeAlerta = function (cp) {
            return (cp.MinAlerta != null && cp.MaxAlerta != null && cp.MinAlerta >= cp.MaxAlerta);
        }

        vm.validateRangeCandado = function (cp) {
            return (cp.MinCandado != null && cp.MaxCandado != null && cp.MinCandado >= cp.MaxCandado);
        }

        vm.validateRanges = function (cp) {
            return (cp.MinAlerta != null && cp.MinCandado != null && cp.MinAlerta < cp.MinCandado) || (cp.MaxAlerta != null && cp.MaxCandado != null && cp.MaxAlerta > cp.MaxCandado);
        }

        vm.cambiarRequerido = function (numeroPregunta) {
            let pregunta = vm.encuesta.Preguntas[numeroPregunta - 1];
            if (pregunta.Requerida) {
                let preguntaAux, preguntaPadre = pregunta.PreguntaPadre;
                for (; preguntaPadre != 0 ; preguntaPadre = preguntaAux.PreguntaPadre) {
                    preguntaAux = vm.encuesta.Preguntas[preguntaPadre - 1];
                    preguntaAux.Requerida = true;
                }
            } else {
                pregunta.Requerida = false;
                vm.encuesta.Preguntas.forEach(function(x) {
                    if (x.PreguntaPadre == numeroPregunta && x.Requerida)
                        pregunta.Requerida = true;
                });
            }
        };

        vm.copyDown = function(pregunta){
            vm.encuesta.Preguntas.push(angular.copy(pregunta));
        };

        vm.moveUp = function (posicion) {
            vm.cambiar(posicion, posicion - 1);
            vm.encuesta.Preguntas.forEach(function(x){
                if(x.PreguntaPadre - 1 == posicion)
                    x.PreguntaPadre = posicion;
                else if(x.PreguntaPadre - 1 == posicion - 1)
                    x.PreguntaPadre = posicion + 1;
            })
        };

        vm.moveDown = function (posicion) {
            var ultimo = vm.encuesta.Preguntas.length - 1;
            vm.cambiar(posicion, posicion + 1);
            vm.encuesta.Preguntas.forEach(function(x){
                if(x.PreguntaPadre - 1 == posicion)
                    x.PreguntaPadre = posicion + 2;
                else if(x.PreguntaPadre - 1 == posicion + 1)
                    x.PreguntaPadre = posicion + 1;
            })
        };

        vm.sendToFirst = function (posicion) {
            var primero = 0;
            vm.cambiar(posicion, primero);
            vm.encuesta.Preguntas.forEach(function(x){
                if(x.PreguntaPadre - 1 == primero)
                    x.PreguntaPadre = posicion + 1;
                else if(x.PreguntaPadre - 1 == posicion)
                    x.PreguntaPadre = primero + 1;
            })
        };

        vm.sendToLast = function (posicion) {
            var ultimo = vm.encuesta.Preguntas.length - 1;
            vm.cambiar(posicion, ultimo);
            vm.encuesta.Preguntas.forEach(function(x){
                if(x.PreguntaPadre - 1 == ultimo)
                    x.PreguntaPadre = posicion + 1;
                else if(x.PreguntaPadre - 1 == posicion)
                    x.PreguntaPadre = ultimo + 1;
            })
        };

        vm.removePregunta = function(pregunta) {
            const indice = vm.encuesta.Preguntas.indexOf(pregunta);
            if(pregunta.TipoPregunta.ActionType === 7 || pregunta.TipoPregunta.ActionType === 8){
                vm.encuesta.audioVideoQuestions.cantidad -= 1;
            } else if (pregunta.TipoPregunta.ActionType === 4){
                vm.encuesta.imageQuestions.cantidad -= 1;
            }else {
                vm.encuesta.simpleQuestions.cantidad -= 1;
            }
            vm.encuesta.Preguntas.splice(indice, 1);
            vm.encuesta.totalQuestions.cantidad--;
            vm.encuesta.Preguntas.forEach(function(x){
                if(x.PreguntaPadre === indice + 1)
                    x.PreguntaPadre = 0;
                else if (x.PreguntaPadre > indice + 1)
                    x.PreguntaPadre--;
            })
        };

        var resetQuestionsAmounts = function () {
            if(!vm.encuesta.audioVideoQuestions){
                vm.encuesta.audioVideoQuestions = {};
            }
            if(!vm.encuesta.imageQuestions){
                vm.encuesta.imageQuestions = {};
            }
            if(!vm.encuesta.simpleQuestions){
                vm.encuesta.simpleQuestions = {};
            }
            if(!vm.encuesta.totalQuestions){
                vm.encuesta.totalQuestions = {};
            }

            vm.encuesta.audioVideoQuestions.cantidad = 0;
            vm.encuesta.imageQuestions.cantidad = 0;
            vm.encuesta.simpleQuestions.cantidad = 0;
            vm.encuesta.totalQuestions.cantidad = 0;
        };

        //Cuando se asigna un tipo de pregunta a una pregunta, configura las mismas en el cotizador.
        vm.setQuestionsType = function () {
            resetQuestionsAmounts();
            angular.forEach(vm.encuesta.Preguntas, function (pregunta) {
                if(pregunta.TipoPregunta.ActionType === 7 || pregunta.TipoPregunta.ActionType === 8){
                    vm.encuesta.audioVideoQuestions.cantidad += 1;
                } else if (pregunta.TipoPregunta.ActionType === 4){
                    vm.encuesta.imageQuestions.cantidad += 1;
                }else {
                    vm.encuesta.simpleQuestions.cantidad += 1;
                }
                vm.encuesta.totalQuestions.cantidad++;
            });
        };

        vm.getVisibilityRequeridaPadre = function (padre) {
            return padre != 0 && (vm.encuesta.Preguntas[padre - 1].TipoPregunta.Nombre == 'SI/NO' || vm.encuesta.Preguntas[padre - 1].TipoPregunta.Nombre == 'OPCION_MULTIPLE');
        };

        vm.updatedPreventivo = function (index) {
            var pregunta = vm.encuesta.Preguntas[index];
            if (pregunta.ControlPreventivo == null)
                pregunta.ControlPreventivo = { PrecioObjetivo: null, 
                    MinAlerta: null, MaxAlerta: null, MinCandado: null, MaxCandado: null };
        };

        function topPage() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }
})();