(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('adminEncuestasCliente', {
            parent: 'app',
            url: '/admin/encuestas-cliente',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/encuestasCliente/adminEncuestasCliente.html',
                    controller: 'AdminEncuestasClienteController',
                    controllerAs: 'vm',
                    resolve: {
                        encuestas: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.queryEncuestasClienteByEstado('PENDIENTE_DE_APROBACION');
                        }]
                    }
                }
            }
        })
        .state('adminEncuestasCliente.aprobar', {
            parent: 'adminEncuestasCliente',
            url: '/aprobar/{encuestaId}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/encuestasCliente/aprobarEncuestaCliente-dialog.html',
                    controller: 'AprobarEncuestaDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        encuesta: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.get($stateParams.encuestaId);
                        }]
                    }
                }).result.then(function() {
                    $state.go('adminEncuestasCliente', null, { reload: true });
                }, function() {
                    $state.go('adminEncuestasCliente', null, { reload: false });
                });
            }]
        }).state('adminEncuestasCliente.detail', {
            parent: 'adminEncuestasCliente',
            url: '/{id}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/encuestasCliente/encuestasCliente-detail.html',
                    controller: 'EncuestasClienteDetailController',
                    controllerAs: 'vm',
                    resolve: {
                        encuesta: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.get($stateParams.id);
                        }]
                    },
                    size: 'md'
                }).result.then(function() {
                    $state.go('adminEncuestasCliente', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();