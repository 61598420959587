(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('DatosEncuestaClienteController', DatosEncuestaClienteController);

    DatosEncuestaClienteController.$inject = ['$scope'];

    function DatosEncuestaClienteController ($scope) {
        var vm = this;
        vm.topPage = topPage;

        vm.encuesta.FechaInicio = new Date();
        vm.encuesta.FechaInicio.setDate(vm.encuesta.FechaInicio.getDate() + 2);
        vm.encuesta.FechaVigencia = new Date(vm.encuesta.FechaInicio);
        vm.encuesta.FechaVigencia.setDate(vm.encuesta.FechaVigencia.getDate() + 5);

        if(vm.encuesta.FechaInicio.getDay() == 6){
            vm.encuesta.FechaInicio.setDate(vm.encuesta.FechaInicio.getDate() + 2);
        }
        if(vm.encuesta.FechaInicio.getDay() == 0){
            vm.encuesta.FechaInicio.setDate(vm.encuesta.FechaInicio.getDate() + 1);
        }
        if(vm.encuesta.FechaVigencia.getDay() == 6){
            vm.encuesta.FechaVigencia.setDate(vm.encuesta.FechaVigencia.getDate() + 2);
        }
        if(vm.encuesta.FechaVigencia.getDay() == 0){
            vm.encuesta.FechaVigencia.setDate(vm.encuesta.FechaVigencia.getDate() + 1);
        }

        vm.minDate = new Date();
        vm.minDate.setDate(vm.minDate.getDate() + 2);

        vm.minDate2 = new Date();

        console.log(vm.criterios);

        $scope.$watch('vm.encuesta.FechaInicio', function(v) {
            vm.minDate2 = new Date(v);
            vm.minDate2.setDate(vm.minDate2.getDate() + 7);

            if (vm.encuesta.FechaVigencia < vm.minDate2)
                vm.encuesta.FechaVigencia = new Date(vm.minDate2);
        });

        vm.onlyWeekendsPredicate = function(date) {
            var day = date.getDay();
            return day != 0 && day != 6;
        };

        vm.contactTypes = [
            'Auditor',
            'Consumidor'
        ];
    }
    
    function topPage() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
})();