(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('UbicacionEncuestaService', UbicacionEncuestaService);

    UbicacionEncuestaService.$inject = ['$q', '$http'];

    function UbicacionEncuestaService ($q, $http) {
        return {
            queryDefaultLists: queryDefaultLists,
            queryUserLists: queryUserLists
        };

        function queryDefaultLists() {
                var deferred = $q.defer();
                $http.get('api/ubicacionListas/default').then(function (response) {
                    deferred.resolve(response.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
    
                return deferred.promise;
        }

        function queryUserLists() {
            var deferred = $q.defer();
            $http.get('api/ubicacionListas').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
    }
    }
})();