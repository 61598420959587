(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('historialCompras', {
            parent: 'app',
            url: '/historial-compras',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/historialCompras/historialCompras.html',
                    controller: 'HistorialComprasController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                getHistorialCompras: ['HistorialComprasService','Principal', function (HistorialComprasService, Principal) {
                    var isAdmin = false;
                    return Principal.identity().then(function (result) {
                        angular.forEach(result.Authorities, function (role) {
                            if(role === 'ROLE_ADMIN'){
                                isAdmin = true;
                            }
                        });
                        if(isAdmin){
                            return HistorialComprasService.queryAllHistorialCompras();
                        }else{
                            return HistorialComprasService.queryHistorialCompras();
                        }
                    });
                }]
            }
        })
        .state('historialCompras.detail', {
            parent: 'historialCompras',
            url: '/{id}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/components/historialCompras/historialCompras-detail.html',
                    controller: 'HistorialComprasDetailController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        getCompra: ['HistorialComprasService', function (HistorialComprasService) {
                            return HistorialComprasService.queryCompra($stateParams.id);
                        }]
                    }
                }).result.then(function() {
                    $state.go('historialCompras', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();