(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('HistorialComprasDetailController', HistorialComprasDetailController);

    HistorialComprasDetailController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'getCompra'];

    function HistorialComprasDetailController ($scope, $stateParams, $uibModalInstance, getCompra) {
        var vm = this;

        vm.historial = getCompra.compra;

        vm.print = function () {
            window.print();
        };

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
