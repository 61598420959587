(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'DataUtils', '$scope', '$uibModal', 'MembresiaService', 'userMembership', '$http', 'userCard', 'userCards', '$state'];

    function SettingsController (Principal, Auth, DataUtils, $scope, $uibModal, MembresiaService, userMembership, $http, userCard, userCards, $state) {
        var vm = this;

        function init() {
            vm.error = null;
            vm.settingsAccount = null;
            vm.success = null;
            vm.userMembership = userMembership;
            vm.userCard = userCard.data;
            vm.userCards = userCards.data;

            Principal.identity().then(function(account) {
                vm.settingsAccount = copyAccount(account);
            });
        }

        function copyAccount(account) {
            return angular.copy(account);
        }

        vm.save = function() {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';

                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        };

        vm.abbreviate = DataUtils.abbreviate;

        vm.byteSize = DataUtils.byteSize;

        vm.setImagen = function ($file, imagen) {
            if ($file && $file.$error == 'pattern') {
                return;
            }

            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply(function() {
                        vm.settingsAccount.Imagen = base64Data;
                        vm.settingsAccount.ImagenContentType = $file.type;
                    });
                };
            }
        };

        vm.clearImage = function() {
            vm.settingsAccount.Imagen = null;
            vm.settingsAccount.ImagenContentType = null;
        };

        vm.cancelMembership = function() {
            MembresiaService.cancelMembership().then(function () {
                vm.userMembership = null;
            });
        };

        vm.deleteCard = function (id) {
            $http.delete('api/payments/deleteCard/'+id).then(function(response){
                var index = vm.userCards.findIndex(function (x) {
                    return x.Id == id;
                });
                if (index > -1) {
                    vm.userCards.splice(index, 1);
                }
            }).catch(function (error) {
                console.log(error);
            });
        };

        vm.setDefault = function (id) {
            $http.post('api/payments/updateDefaultCard', { Id : id}).then(function(response){
                vm.userCard = vm.userCards.find(function (x) {
                    return x != null && x.Id == response.data.Id;
                });
            }).catch(function (error) {
                console.log(error);
            });
        };


        vm.cardModal = function() {
            $uibModal.open({
                templateUrl: 'app/account/settings/settingsAddCard-dialog.html',
                controller: 'SettingsAddCardController',
                controllerAs: 'vm',
                size: 'md'
            }).result.then(function(response) {
                if (response != null) {
                    vm.userCards.push(response);
                }
            }, function() {

            });
        };

        init();
    }
})();
