(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CarritoController', CarritoController);

    CarritoController.$inject = ['CarritoProvider', 'userMembresia', '$uibModal', 'encuestasCarrito', 'CarritoService',
        'contactosLibres', 'preguntasLibres', 'accesoComunidad', 'bonificaciones', 'EncuestasClienteService',
        'CotizadorService', 'membresiaCarrito', 'MembresiaService'];

    function CarritoController (CarritoProvider, userMembresia, $uibModal, encuestasCarrito, CarritoService,
                                contactosLibres, preguntasLibres, accesoComunidad, bonificaciones, EncuestasClienteService,
                                CotizadorService, membresiaCarrito, MembresiaService) {
        var vm = this;
        var carritoSubscription;
        vm.habilitadaCompra = habilitadaCompra;
        vm.descripcionContactos = "Contactos bonificados / Contactos disponibles";

        var accordion = document.getElementById('downloadTemplete');

        function init () {
            carritoSubscription = CarritoProvider.getCarritoSubject().subscribe(function(value){
                vm.encuestas = value;
            });

            CotizadorService.reloadContactosLibres();

            vm.processingPayment = false;

            vm.membresia = userMembresia;
            vm.membresiaCarrito = membresiaCarrito;

            vm.hasMembership = (vm.membresia != null);

            vm.recargo = vm.hasMembership ? 0 : accesoComunidad.data.Valor;
            vm.bonificaciones = bonificaciones.data;

            vm.cantidadContactos = contactosLibres;
            vm.cantidadPreguntas = preguntasLibres;
        }

        vm.removeEncuesta = function(encuesta) {
            $uibModal.open({
                templateUrl: 'app/components/carrito/carritoRemoveEncuesta-dialog.html',
                controller: 'CarritoRemoveEncuestaController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    encuesta: function () {
                        return encuesta;
                    }
                }
            });
        };

        // vm.comprarMembresia = function () {
        //     $uibModal.open({
        //         templateUrl: 'app/components/carrito/carritoCompra-dialog.html',
        //         controller: 'CarritoCompraDialogController',
        //         controllerAs: 'vm',
        //         size: 'md',
        //         resolve: {
        //             encuestas: function () {
        //                 return vm.encuestas;
        //             },
        //             precioTotal: function () {
        //                 return vm.getTotalPrecio();
        //             },
        //             lastFactura: function () {
        //                 return CarritoService.getLastCompra();
        //             },
        //             membresia: function () {
        //                 return vm.membresiaCarrito;
        //             }
        //         }
        //     }).closed.then(function () {
        //         CotizadorService.reloadContactosLibres()
        //         MembresiaService.getMembresiaCarrito().then(function (result) {
        //             vm.membresiaCarrito = result;
        //         });
        //     });
        // };

        vm.processPurchase = function (precio) {
            for (var i=0;i<vm.encuestas.length;i++) {
                EncuestasClienteService.save(vm.encuestas[i])
            }

            //Si el precio a pagar es menor que 0 confirmamos la compra
            if(precio <= 0) {
                vm.processingPayment = true;
                var payment = {
                    EncuestaDTOs: vm.encuestas,
                    Monto: 0,
                    Type: "CARD",
                    Factura: "",
                    Nombre: "",
                    Apellido: "",
                    Direccion: "",
                    Email: "",
                    RFC: "",
                    OrderId: 0
                };

                CarritoService.paySurveys(payment).then(function(response) {
                    CarritoProvider.fetchEncuestasCarrito();
                    $window.location.href = '/#/encuestas-cliente';
                });
            }


            $uibModal.open({
                templateUrl: 'app/components/carrito/carritoCompra-dialog.html',
                controller: 'CarritoCompraDialogController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    encuestas: function () {
                        return vm.encuestas;
                    },
                    precioTotal: function () {
                        return vm.getTotalPrecio();
                    },
                    lastFactura: function () {
                        return CarritoService.getLastCompra();
                    },
                    membresia: function () {
                        return vm.membresiaCarrito;
                    }
                }
            }).result.then(function () {
                CotizadorService.reloadContactosLibres();
                MembresiaService.getMembresiaCarrito().then(function (result) {
                    vm.membresiaCarrito = result;
                });
            });
        };

        vm.getTotalContactos = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + encuesta.CantidadContactos;
            }, 0);
        };

        vm.getContactosBonificados = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.min(encuesta.CantidadContactos, vm.cantidadContactos);
            }, 0);
        };

        vm.getContactosBonificadosTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.min(encuesta.CantidadContactos, vm.cantidadContactos) * encuesta.precioContacto;
            }, 0);
        };

        vm.getPreguntasNormalesTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + Math.max(encuesta.Preguntas.length - vm.cantidadPreguntas, 0);
            }, 0);
        };

        vm.getPrecioPreguntasNormalesTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                var exPreguntas = Math.max(encuesta.Preguntas.length-vm.cantidadPreguntas, 0);
                var totalPreguntas = encuesta.Preguntas
                    .map(function (pregunta) {
                        return pregunta.TipoPregunta.Precio;
                    })
                    .sort()
                    .reverse()
                    .slice(0, exPreguntas)
                    .reduce(function (n1, x) {
                        return n1+x;
                    }, 0);

                return n + totalPreguntas;
            }, 0);
        };

        vm.getSubtotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + encuesta.CantidadContactos * encuesta.precioContacto;
            }, 0);
        };

        vm.getTotal = function () {
            return vm.encuestas.reduce(function (n, encuesta) {
                return n + CotizadorService.getPrecioEncuesta(encuesta);
            }, 0);
        };

        vm.getPorcentajeBonificacion = function (total) {
            return CotizadorService.getBonificacion(total);
        };

        vm.getTotalPrecio = function () {
            return CotizadorService.getTotal(vm.encuestas, vm.hasMembership);
        };

        function habilitadaCompra(encuesta) {
            if(encuesta.Estado.Nombre !== "CARRITO"){
                return false
            }
            else{
                vm.fechaInicioValida = new Date();
                vm.fechaVigenciaValida = new Date();
                vm.fechaInicioValida.setDate(vm.fechaInicioValida.getDate() + 2);
                vm.fechaVigenciaValida.setDate(vm.fechaInicioValida.getDate() + 5);
                var dateInicio = new Date(encuesta.FechaInicio);
                var dateVigencia = new Date(encuesta.FechaVigencia);
                if( encuesta.FechaInicio === "" || vm.fechaInicioValida >= dateInicio)
                {
                    encuesta.CantidadContactos = 0;
                    return false;
                }
                if( encuesta.FechaVigencia === "" || vm.fechaVigenciaValida >= dateVigencia)
                {
                    encuesta.CantidadContactos = 0;
                    return false;
                }
                return true;
            }
        }
        window.scrollTo(0, 0);

        init();
    }
})();
