(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('CarritoService', CarritoService);

    CarritoService.$inject = ['$q', '$http'];

    function CarritoService($q, $http) {
        return {
            queryCarrito: queryCarrito,
            queryMembresiaCarrito: queryMembresiaCarrito,
            paySurveys: paySurveys,
            getCotizadorVariable: getCotizadorVariable,
            queryCotizadorBonificacion: queryCotizadorBonificacion,
            getLastCompra: getLastCompra,
            getCard: getCard,
            getCards: getCards
        };

        function queryCarrito() {
            var deferred = $q.defer();

            $http.get('api/encuestas/carrito').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function queryMembresiaCarrito() {
            var deferred = $q.defer();

            $http.get('api/membresia/carrito').then(function (response) {
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function paySurveys (payment) {
            var deferred = $q.defer();

            console.log('payment: ', payment);
            $http.post('api/payments/create/charge', payment).then(function(response){
                deferred.resolve(response.data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getCotizadorVariable(nombre) {
            return $http.get('api/cotizador/'+nombre);
        }

        function queryCotizadorBonificacion() {
            return $http.get('api/cotizador/bonificacion');
        }

        function getLastCompra() {
            return $http.get('api/compras/lastByUser');
        }

        function getCard() {
            return $http.get('api/payments/getcard');
        }

        function getCards() {
            return $http.get('api/payments/getcardall');
        }
    }
})();