(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('UbicacionEncuestaClienteSaveLocationsDialogController', UbicacionEncuestaClienteSaveLocationsDialogController);

        UbicacionEncuestaClienteSaveLocationsDialogController.$inject = ['$uibModalInstance', 'ubicacions', '$http'];

    function UbicacionEncuestaClienteSaveLocationsDialogController ($uibModalInstance, ubicacions, $http) {
        var vm = this;

        vm.ubicacionLista = {}
        vm.ubicacionLista.Ubicacions = ubicacions

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function () {
            angular.forEach(vm.ubicacionLista.Ubicacions, function(ubicacion){
                ubicacion.Latitud = ubicacion.Pos[0];
                ubicacion.Longitud = ubicacion.Pos[1];
            });
                    
            $http.post('api/ubicacionListas', vm.ubicacionLista).then(function(response){
                $uibModalInstance.close('ok');
            }).catch(function (error) {
                console.log(error);
            });
        };
    }
})();