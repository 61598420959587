(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('carrito', {
            parent: 'app',
            url: '/carrito',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/carrito/carrito.html',
                    controller: 'CarritoController',
                    controllerAs: 'vm',
                    resolve: {
                        userMembresia: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getUserMembership();
                        }],
                        encuestasCarrito: ['CarritoService', function (CarritoService) {
                            return CarritoService.queryCarrito();
                        }],
                        contactosLibres: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getContactosLibres();
                        }],
                        preguntasLibres: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getPreguntasLibres();
                        }],
                        accesoComunidad: ['CarritoService', function (CarritoService) {
                            return CarritoService.getCotizadorVariable('ACCESO_COMUNIDAD');
                        }],
                        bonificaciones: ['CarritoService', function (CarritoService) {
                            return CarritoService.queryCotizadorBonificacion();
                        }],
                        membresiaCarrito: ['MembresiaService', function (MembresiaService) {
                            var membresia = MembresiaService.getMembresiaCarrito();
                            return membresia;
                        }]
                    }
                }
            }
        });
    }
})();