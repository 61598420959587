(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ClienteManagementDeleteController', ClienteManagementDeleteController);

    ClienteManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Cliente'];

    function ClienteManagementDeleteController ($uibModalInstance, entity, Cliente) {
        var vm = this;

        function init () {
            vm.cliente = entity;
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function(id) {
            Cliente.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

        init();
    }
})();
