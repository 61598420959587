(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('CardFormController', CardFormController);

    CardFormController.$inject = ['$scope'];

    function CardFormController ($scope) {
        var vm = this;

        var visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
        var mastercard = new RegExp("^5[1-5][0-9]{14}$");
        var amex = new RegExp("^3[47][0-9]{13}$");

        $scope.$watch('vm.card.number', function (n) {
            if (n) {
                var cardNumber = n.replace(/\D/g,'');

                if (cardNumber.length > 16) {
                    cardNumber = cardNumber.substring(0, 16);
                }

                if (cardNumber.length == 16)
                    vm.card.number = cardNumber.match(/.{1,4}/g).join(' ');
                if (cardNumber.length == 15)
                    vm.card.number = cardNumber.substring(0, 4) + ' ' + cardNumber.substring(4, 10) + ' ' + cardNumber.substring(10, 15);

                vm.card.type = vm.cardBrand(vm.card.number);
            }
        });

        $scope.$watch('vm.card.expiredDate', function (d) {
            if (d) {
                var date = d.replace(/\D/g,'');

                if (date.length > 4) {
                    date = date.substring(0, 4);
                }

                vm.card.expiredDate = date.match(/.{1,2}/g).join('/');
            }
        });

        $scope.$watch('vm.card.CSV', function (c) {
            if (c) {
                var CSV = c.replace(/\D/g,'');

                if (CSV.length > 4) {
                    CSV = CSV.substring(0, 3);
                }

                vm.card.CSV = CSV;
            }
        });

        vm.isCardNumberValid = function(cardNumber) {
            if (cardNumber) {
                var cleanCardNumber = cardNumber.replace(/ /g, '');

                return visa.test(cleanCardNumber) || mastercard.test(cleanCardNumber) || amex.test(cleanCardNumber);
            }

            return false;
        };

        vm.cardBrand = function(cardNumber) {
            if (cardNumber) {
                var cleanCardNumber = cardNumber.replace(/ /g, '');

                if(visa.test(cleanCardNumber)) {
                    return 'VISA';
                }

                if(mastercard.test(cleanCardNumber)) {
                    return 'MASTERCARD';
                }

                if(amex.test(cleanCardNumber)) {
                    return 'AMEX';
                }
            }

            return null;
        };
    }
})();