(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ModalEncuestaController', ModalEncuestaController);

    ModalEncuestaController.$inject = [];

    function ModalEncuestaController () {
        var vm = this;

        vm.saveEncuesta = saveEncuesta;

        function saveEncuesta (data) {
            vm.isLoading = true;
            vm.encuesta.Localizacion = vm.positions;
            vm.encuesta.Aceptacion = data.Encuesta.Aceptacion;
            if (vm.encuesta.Aceptacion == 'undefined' || vm.encuesta.Aceptacion == null || vm.encuesta.Aceptacion == false) {
                vm.showAlert("Se deben aceptar los términos y condiciones");
            }
            else {
                if (vm.encuesta.Aceptacion == true) {
                    date = new Date();
                    var dateAceptacion = moment(date, 'DD/MM/YYYY').format();
                    data.Encuesta.Aceptacion = dateAceptacion;
                }

                EncuestasHttpServices.encuestaCreateRequest(data,
                    function (data) {
                        vm.isLoading = false;
                        vm.dataResponse = data;
                        var detalles = data.encuestaDetalles;
                        var preguntas = data.preguntas;
                        var status = data.status;
                        if (status == 1) {
                            vm.showModalTerms();
                        }
                        else {
                            vm.isLoading = false;
                            vm.showAlert('Ocurrio un error al registrar la encuesta');
                        }
                    },
                    function (response) {
                        vm.isLoading = false;
                        alert("Ha ocurrido un error");
                    }
                );
            }
        }
    }
})();