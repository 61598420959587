(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ResultsEncuestasClienteController', ResultsEncuestasClienteController);

    ResultsEncuestasClienteController.$inject = ['$scope', '$stateParams', '$sce',
                                                '$http', 'BACKOFFICE_API_URL', '$uibModal', 'EncuestasClienteService', 'Principal', '$timeout', 'NgMap', '$interval'];

    function ResultsEncuestasClienteController ($scope, $stateParams, $sce, $http,
                                                BACKOFFICE_API_URL, $uibModal, EncuestasClienteService, Principal, $timeout, NgMap, $interval) {
        //init variables
        var vm = this;
        vm.clear = clear;
        vm.page = 0;
        vm.firstLoad = true;
        vm.instance = 0;
        vm.encuestaResults = {};
        var aprobados = $stateParams.displayOnly;
        var encuestaId = $stateParams.id;
        vm.dataIsReady = false;
        vm.contactoDataIsReady = false;
        vm.estadisticasDataIsReady = false;
        vm.photoIsReady = false;
        vm.mediaDictionary = {};
        vm.redirect = redirect;
        vm.efectividad = '';
        vm.ventaTotal = '';
        vm.isLoadoingEstadisticas = false;
        vm.isLoadoingEstadisticasEfectividad = false;
        vm.showing = false;
        vm.progressCheckAbierto = false;

        vm.efectividadVerTodos = true;
        vm.firstLoadEfectividad = true;

        vm.fechaDesde = new Date();
        vm.fechaHasta = new Date();
        vm.isFiltered = false;

        vm.encuesta = null;
        vm.encuestadorNameFilter = '';

        vm.mostrarGeosTodos = false;
        vm.geosTodos = [];

        vm.zipsEnProgreso = [];
        vm.path = '';

        function redirect() {
            var containerEncuestasHeight = document.getElementById("container-encuestas").clientHeight;
            var heightToTop = (containerEncuestasHeight + 300) + "px";

            document.getElementById("redirect").style.top =  heightToTop;
            document.getElementById("redirect").scrollIntoView({block: "start"});
        }

        vm.getResultEstadisticas = function() {
            vm.estadisticasDataIsReady = false;
            vm.isLoadoingEstadisticas = true;

            var encuestadorId = 0;
            if(!vm.efectividadVerTodos){
                if(vm.encuestador != null){
                    encuestadorId = vm.encuestador.Id;
                }
            }

            var instancia = 0;
            if(vm.NumeroInstancias != null && vm.instance != -1){
                instancia = vm.NumeroInstancias[vm.instance];
            }

            EncuestasClienteService.getEncuestaBySitiowebId(encuestaId).then(function(result) {
                vm.encuesta = result;
            });

            EncuestasClienteService.getResultEstadisticas(encuestaId, encuestadorId, aprobados, instancia,
                                                            vm.fechaDesde, vm.fechaHasta).then(function(result) {
                vm.estadisticas = result.data.Estadisticas;
                vm.estadisticasDataIsReady = true;
                
                vm.RespuestasCount = result.data.RespuestasCount;
                vm.EncuestadoresCount = result.data.EncuestadoresCount;

                angular.forEach(vm.estadisticas, function(pregunta){
                    pregunta.chartData = [];
                    pregunta.labels = [];
                    angular.forEach(pregunta.Opciones, function(opcion){
                        pregunta.chartData.push(opcion.Cantidad / pregunta.Cantidad);
                        pregunta.labels.push(opcion.Valor);

                    });
                });

                vm.isLoadoingEstadisticas = false;


                vm.getResultEstadisticasEfectividad();

            });
        };

        vm.getResultEstadisticas();

        vm.getResultEstadisticasEfectividad = function(){
            vm.isLoadoingEstadisticasEfectividad = true;

            var encuestadorId = 0;
            if(!vm.efectividadVerTodos){
                if(vm.encuestador != null){
                    encuestadorId = vm.encuestador.Id;
                }
            }

            var instancia = 0;
            if(vm.NumeroInstancias != null && vm.instance != -1){
                instancia = vm.NumeroInstancias[vm.instance];
            }


            EncuestasClienteService.getResultEstadisticasEfectividad(encuestaId, encuestadorId, instancia,
                                                                    vm.fechaDesde, vm.fechaHasta, aprobados)
                                                                                                .then(function(result) {
                // Efectividad
                if(result.data.Efectividad != null){
                    vm.efectividad = result.data.Efectividad.InstanciasVenta + '/' + result.data.Efectividad.TotalInstancias;

                    vm.porcentajeEfectividad = parseFloat(parseFloat(result.data.Efectividad.InstanciasVenta) /
                        parseFloat(result.data.Efectividad.TotalInstancias) * 100).toFixed(2);
                    if(isNaN(vm.porcentajeEfectividad)){
                        vm.efectividad = '';
                        vm.porcentajeEfectividad = "0.0%";
                    }else{
                        vm.porcentajeEfectividad = vm.porcentajeEfectividad + "%";
                    }
                }else{
                    vm.efectividad = '';
                }

                // Venta Total
                if(result.data.VentaTotal != null){
                    vm.ventaTotal = result.data.VentaTotal;
                    vm.ventaTotalFormatted = result.data.VentaTotalFormatted;
                }else{
                    vm.ventaTotal = 0;
                }

                if(result.data.Efectividad != null
                    && result.data.Efectividad.InstanciasVenta != null
                    && result.data.Efectividad.InstanciasVenta > 0
                    && vm.ventaTotal != null){
                    //venta promedio
                    var ventaProm = parseFloat(vm.ventaTotal / result.data.Efectividad.InstanciasVenta).toFixed(2);
                    vm.ventaPromedio = "MXN $ " + result.data.VentaPromedioFormatted;
                }


                vm.isLoadoingEstadisticasEfectividad = false;


                // if(vm.efectividad != '' && vm.ventaTotal != 0) {
                    vm.showing = true;
                // }

                });

        };

        // vm.getResultEstadisticasEfectividad();

        vm.getEncuestadores = function(page) {
            vm.dataIsReady = false;
            vm.contactoDataIsReady = false;

            EncuestasClienteService.getEncuestadoresByEncuestaResults(page, encuestaId, aprobados, vm.fechaDesde, vm.fechaHasta, vm.encuestadorNameFilter)
                                    .then(function(result) {
                
                vm.totalPages = result.data.Total;
                
                if(result.data.Content)
                    vm.encuestadores = result.data.Content.Contactos;

                if(vm.encuestadores[0]) {
                    vm.encuestador  = vm.encuestadores[0];
                    //resultados: cargo el detalle
                    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    vm.getResultsByEncuestadorId(vm.encuestador, true);
                } else {
                    vm.encuestador = null;
                    vm.contactoDataIsReady = true;
                }
                vm.dataIsReady = true;
            });
        };

        vm.getEncuestadores();


        vm.getResultsByEncuestadorId = function(encuestador, firstLoad, numeroInstancia){
            vm.contactoDataIsReady = false;
            vm.mostrarGeosTodos = false;
            vm.encuestador = encuestador;
            
            if(!numeroInstancia){
                numeroInstancia = null;
                vm.instance = -1;
            }

            if(!firstLoad){
                firstLoad = false;
            }

            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            if(vm.efectividadVerTodos){
                vm.instance = -1;
            }

            vm.getResultEstadisticas();

            EncuestasClienteService.getResultsByEncuestadorId(encuestaId, encuestador.Id, aprobados, vm.fechaDesde,
                                                            vm.fechaHasta, numeroInstancia, timeZone)
                                                            .then(function(result) {

                vm.idSelected = encuestaId;
                vm.contacto = result.data.Contactos[0];
                vm.preguntas = result.data.Preguntas;

                //REMOVE
                if (result.data.Geos != null) {
                    vm.geos = result.data.Geos;
                } else {
                    vm.geos = result.data.geos;
                }
                vm.NumeroInstancias = result.data.NumeroInstancias;

                //parse geos
                vm.geosJson = [];
                var geoId = 1;
                var geo;
                var indexGeo = 0;
                vm.geos.forEach(function (g, i) {
                    //REMOVE
                    if (result.data.Geos != null) {
                        geo = JSON.parse(g.Geo);
                    } else {
                        geo = JSON.parse(g);
                    }


                    geo.id = geoId++;
                    geo.latitude = parseFloat(geo.latitude);
                    geo.longitude = parseFloat(geo.longitude);
                    geo.NumeroInstancia = g.NumeroInstancia;

                    if (g.VentaTotal != null) {
                        geo.ventaTotal = 'VENTA: MXN $' + parseFloat(g.VentaTotal);
                        geo.icon = 'content/images/markers/green-marker.png';
                    } else {
                        geo.ventaTotal = "NO HUBO VENTAS";
                        geo.icon = 'content/images/markers/red-marker.png';
                    }
                    vm.geosJson.push(geo);

                });


                vm.catalogoTotal = 0;
                if(vm.contacto && vm.contacto.Resultados){
                    angular.forEach(vm.contacto.Resultados, function(resultado) {
                        //catalogo
                        angular.forEach(resultado.Respuestas, function (respuesta) {
                            if (respuesta.RespuestasCatalogo != null) {
                                angular.forEach(respuesta.RespuestasCatalogo, function (r) {
                                    vm.catalogoTotal += r.Precio * r.ValorNumerico;
                                });
                            }
                        });
                        // vm.catalogoTotal = Math.round(vm.catalogoTotal);


                        resultado.FechaSubida = new Date(resultado.FechaSubida);
                        for (var i=0;i<resultado.Respuestas.length;i++) {
                            var respuesta = resultado.Respuestas[i];
                            var type = result.data.Preguntas[i].Type;
                            if(respuesta != null){
                                if (respuesta.ValorGeo) {
                                    respuesta.ValorGeo = JSON.parse(respuesta.ValorGeo);
                                    respuesta.ValorGeo.latitude = parseFloat(respuesta.ValorGeo.latitude);
                                    respuesta.ValorGeo.longitude = parseFloat(respuesta.ValorGeo.longitude);
                                }

                                if(type === 4 || type === 7 || type === 8) {
                                    if(respuesta.ValorImagePath == null){
                                        (function(id, tipo) {
                                            EncuestasClienteService.getImage(id, tipo).then(function(result) {
                                                vm.mediaDictionary["" + id] = result;
                                            });
                                        })(respuesta.Id, type);
                                    }
                                }
                            }
                        }
                    });
                }

                vm.contactoDataIsReady = true;
                vm.firstLoad = firstLoad;

                if (numeroInstancia) {
                    vm.instance = vm.NumeroInstancias.indexOf(numeroInstancia);
                }

            });
        };
        
        vm.changeInstance = function (index) {
            vm.instance = index;
            vm.firstLoad = false;

            var numeroInstancia = vm.NumeroInstancias[index];
            vm.getResultsByEncuestadorId(vm.encuestador, false, numeroInstancia);
        };

        vm.changeInstanceBynro = function (numeroInstancia) {
            vm.firstLoad = false;
            vm.getResultsByEncuestadorId(vm.encuestador, false, numeroInstancia);
        };

        vm.changeInstanceGeo = function ($event) {
            var nroInstancia = 0;
            angular.forEach(vm.geosJson, function (geo) {
                if (geo.latitude === $event.latLng.lat()) {
                    nroInstancia = geo.NumeroInstancia;
                    vm.changeInstanceBynro(nroInstancia);
                }
            });
        };

        vm.changePage = function (page) {
            vm.page = page;
            vm.getEncuestadores(page);
        };


        vm.doughnutChartOptions = {
            aspectRatio: 1,
            tooltips: {
                enabled: false
            },
            hover: {
                mode: null
            }
        };

        vm.filtrarPorFecha = function () {
            vm.encuestadorNameFilter = ""
            vm.isFiltered = true;
            vm.getEncuestadores();
        };

        vm.limpiarFiltros = function() {
            vm.fechaDesde = new Date();
            vm.fechaHasta = new Date();
            vm.instance = -1;
            vm.page = 0;
            vm.encuestadorNameFilter = "";
            vm.getEncuestadores();
        };

        vm.exportToCSV = function(encuestador) {
            var fechaDesde = vm.fechaDesde;
            var fechaHasta = vm.fechaHasta;
            
            var encuestadorId = null;
            if(encuestador){
                encuestadorId = encuestador.Id;
            }
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            EncuestasClienteService.exportResultsToCSV(vm.idSelected, false, fechaDesde, fechaHasta, encuestadorId, timeZone).then(function (result) {
                let p = $interval(vm.checkearZip, 5000, 0, true, result);
                let fileName = vm.encuesta.Nombre;
                fileName += '_' + vm.fechaDesde + '_' + vm.fechaHasta;
                let options = ['', encuestaId, null, vm.fechaDesde, vm.fechaHasta];
                vm.zipsEnProgreso.push({dictId: result, nombre: fileName, check: p, status: 'Comenzando', link: '', options: options, csv: true});
            });
        };

        vm.exportToPDF = function(encuestador) {
            var defaultEmail = Principal.getIdentitySubject().getValue().UserName;
            var modal = $uibModal.open({
                templateUrl: "app/components/resultEncuestaCliente/mediaEmailDialog.html",
                controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                    $scope.isMediaExport = false;
                    $scope.defaultEmail = defaultEmail;
                    $scope.email = defaultEmail;
                    $scope.slides = null;
                    $scope.cargando = false;

                    $scope.cerrar = function() {
                        $uibModalInstance.close($scope.email);
                    }

                    $scope.dismissear = function() {
                        $uibModalInstance.dismiss();
                    }
                }]});

            modal.result.then(function (result) {
                var fechaDesde = vm.fechaDesde;
                var fechaHasta = vm.fechaHasta;
                
                var encuestadorId = null;
                if(encuestador){
                    encuestadorId = encuestador.Id;
                }
                
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                EncuestasClienteService.exportResultsToPDF(result, encuestaId, false, fechaDesde, fechaHasta, encuestadorId, timeZone);
            });
        };

        function clear () {
            
        }

        vm.isMediaAvailable = function(respuestaId) {
            return (vm.mediaDictionary && vm.mediaDictionary[respuestaId] !== undefined)
        };

        vm.getMedia = function(respuestaId) {
            return vm.mediaDictionary[respuestaId];
        };

        vm.refreshDatos = function () {
            if(vm.efectividadVerTodos){
                vm.instance = -1;
            }
            // vm.getResultEstadisticasEfectividad();
            vm.getResultEstadisticas();
        }

        vm.abrirModal = function(paraEncuestador) {
            EncuestasClienteService.getMediaForCarousel(encuestaId, 6, 0, paraEncuestador ? vm.encuestador.Id : null, vm.fechaDesde, vm.fechaHasta).then(function(slidesIniciales) {
                var defaultEmail = Principal.getIdentitySubject().getValue().UserName;
                var modal = $uibModal.open({
                    templateUrl: "app/components/resultEncuestaCliente/mediaEmailDialog.html",
                    controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                        $scope.isMediaExport = true;
                        $scope.defaultEmail = defaultEmail;
                        $scope.email = defaultEmail;
                        $scope.slides = slidesIniciales;
                        $scope.cargando = false;
    
                        $scope.cerrar = function() {
                            $uibModalInstance.close($scope.email);
                        }
    
                        $scope.dismissear = function() {
                            $uibModalInstance.dismiss();
                        }
    
                        $scope.onCarouselAfterChange = function(slide) {
                            if (slide === $scope.slides.length - 1) {    
                                $scope.cargando = true;
                                EncuestasClienteService.getMediaForCarousel(encuestaId, 6, $scope.slides.length, paraEncuestador ? vm.encuestador.Id : null, vm.fechaDesde, vm.fechaHasta).then(function(slides) {
                                    slides.forEach(function(x) {
                                        $scope.slides.push(x);
                                    });
                                    $scope.cargando = false;
                                })
                            }
                        }

                    }]});

                    modal.result.then(function (result) {
                        if (paraEncuestador) {
                            vm.agregarALista();
                        } else {
                            vm.agregarSinEncuestador();
                        }
                    });
            })
        }

        vm.agregarSinEncuestador = function() {
            EncuestasClienteService.sendMediaEmail('', encuestaId, null, vm.fechaDesde, vm.fechaHasta).then(function (result) {
                let p = $interval(vm.checkearZip, 5000, 0, true, result);
                let fileName = vm.encuesta.Nombre;
                fileName += '_' + vm.fechaDesde + '_' + vm.fechaHasta;
                let options = ['', encuestaId, null, vm.fechaDesde, vm.fechaHasta];
                vm.zipsEnProgreso.push({dictId: result, nombre: fileName, check: p, status: 'Comenzando', link: '', options: options, csv: false});
            })
        }

        vm.agregarALista = function() {
            EncuestasClienteService.sendMediaEmail('', encuestaId, vm.encuestador === undefined || vm.encuestador === null ? null : vm.encuestador.Id, vm.fechaDesde, vm.fechaHasta).then(function (result) {
                let p = $interval(vm.checkearZip, 5000, 0, true, result);
                let fileName = vm.encuestador === undefined || vm.encuestador === null ? vm.encuesta.Nombre : vm.encuestador.NombreApellido;
                fileName += '_' + vm.fechaDesde + '_' + vm.fechaHasta;
                let options = ['', encuestaId, vm.encuestador === undefined || vm.encuestador === null ? null : vm.encuestador.Id, vm.fechaDesde, vm.fechaHasta];
                vm.zipsEnProgreso.push({dictId: result, nombre: fileName, check: p, status: 'Comenzando', link: '', options: options, csv: false});
            })
        }

        vm.descargarOReintentar = function(item) {
            if (item.status === 'Completado' || item.status === 'Vacio') {
                vm.quitarDeLista(item);
            } else {
                EncuestasClienteService.sendMediaEmail(item.options[0], item.options[1], item.options[2], item.options[3], item.options[4]).then(function (result) {
                    let p = $interval(vm.checkearZip, 5000, 0, true, result);
                    let index = vm.zipsEnProgreso.indexOf(item);
                    item.dictId = result;
                    item.check = p;
                    item.status = 'Comenzando';
                })
            }
        }

        vm.quitarDeLista = function(item) {
            let index = vm.zipsEnProgreso.indexOf(item);
            if(index !== -1) {
                vm.zipsEnProgreso.splice(index, 1);
                if (vm.zipsEnProgreso.length == 0) {
                    vm.progressCheckAbierto = false;
                }
            }
        }

        vm.checkearZip = function(dictId) {
            EncuestasClienteService.checkForFile(dictId).then(function(result) {
                let item = vm.zipsEnProgreso.find(function(x) {return x.dictId === dictId;});
                switch (result) {
                    case 'Empty':
                        item.status = 'Vacio';
                        break;
                    case 'Running':
                        item.status = 'Procesando';
                        break;
                    case 'RanToCompletion':
                        item.status = 'Completado';
                        break;
                    default:
                        item.status = 'Error';
                        break;
                }
                $scope.$evalAsync();
                if(result === 'RanToCompletion' || result === 'Faulted' || result == 'Canceled' || result === 'Empty') {
                    $interval.cancel(item.check);
                    item.link = result === 'RanToCompletion' ? EncuestasClienteService.getLinkForFile(item.dictId, item.csv) : '';
                }
            })
        }

        vm.buscarGeosTodos = function() {
            vm.contactoDataIsReady = false;
            EncuestasClienteService.buscarGeosTodos(encuestaId, vm.fechaDesde, vm.fechaHasta).then(function(result) {

                vm.mostrarGeosTodos = true;
                vm.geosTodos = result;

                vm.dynMarkers = [];
                NgMap.getMap("geosTodos").then(function(map) {
                    var bounds = new google.maps.LatLngBounds();
                    for(var i = 0; i < vm.geosTodos.length; i++) {
                        const valorGeo = JSON.parse(vm.geosTodos[i].Geo)
                        var latLng = new google.maps.LatLng(valorGeo.latitude, valorGeo.longitude);
                        vm.dynMarkers.push(new google.maps.Marker({
                            position: latLng, 
                            title: vm.geosTodos[i].NombreEncuestador + "\n" + (vm.geosTodos[i].VentaTotal ? 'VENTA: MXN $' + parseFloat(vm.geosTodos[i].VentaTotal) : 'NO HUBO VENTAS'),
                            icon: vm.geosTodos[i].VentaTotal ? 'content/images/markers/green-marker.png' : 'content/images/markers/red-marker.png'
                        }));
                        bounds.extend(latLng);
                    }
                    vm.clusterer = new MarkerClusterer(map, vm.dynMarkers, { maxZoom: 12, imagePath: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m' });
                    map.setCenter(bounds.getCenter());
                    map.fitBounds(bounds);
                });
            });
        }
    }
})();