(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('UserManagementAssignClienteDialogController',UserManagementAssignClienteDialogController);

    UserManagementAssignClienteDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'paginationConstants', 'Cliente'];

    function UserManagementAssignClienteDialogController ($stateParams, $uibModalInstance, entity, User, paginationConstants, Cliente) {
        var vm = this;

        function init() {
            vm.user = entity;
            vm.page = 1;
            vm.itemsPerPage = paginationConstants.itemsPerPage;
            vm.loadAll();
        }

        vm.loadAll = function() {
            window.scrollTo(0, 0);

            Cliente.query({
                Number: vm.page - 1,
                TotalElements: vm.itemsPerPage
            }, onSuccess, onError);
        };

        function onSuccess (data) {
            vm.totalItems = data.TotalElements;
            vm.queryCount = vm.totalItems;
            vm.page = data.Number + 1;
            vm.clientes = data.Content;
        }

        function onError (error) {
            AlertService.error(error.data.message);
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.save = function () {
            vm.user.Cliente = vm.clientes.find(function (cliente) {
                return cliente.Id === parseInt(vm.user.Cliente.Id);
            });
            User.update({id:vm.user.Id},vm.user, onSaveSuccess, onSaveError);
        };

        init();
    }
})();