(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(function () {
            Stripe.setPublishableKey('pk_test_6T0tyRv63V9pxTvJmiJnCGZQ');
        })
        .controller('SettingsAddCardController', SettingsAddCardController);

    SettingsAddCardController.$inject = ['$uibModalInstance', '$http'];

    function SettingsAddCardController ($uibModalInstance, $http) {
        var vm = this;

        vm.card = {
            name: "APPROVED",
            number: "4268 0700 0000 0002",
            expiredDate: "01/20",
            CSV: "123"
        };

        function init () {

        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.addCard = function() {
            var card = {
                Number: vm.card.number.replace(/\D/g,''),
                ExpMonth: vm.card.expiredDate.split('/')[0],
                ExpYear: '20' + vm.card.expiredDate.split('/')[1],
                Cvc: vm.card.CSV,
                Name: vm.card.name,
                Type: vm.card.type
            };

            $http.post('api/payments/addCard', card).then(function(response){
                $uibModalInstance.close(response.data);
            }).catch(function (error) {
                console.log(error);
                $uibModalInstance.close(null);
            });
        };

        init();
    }
})();
