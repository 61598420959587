(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal ($q, Account) {
        var _identitySubject = new Rx.BehaviorSubject(undefined),
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            getIdentitySubject: getIdentitySubject,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function authenticate (identity) {
            _identitySubject.onNext(identity);
            _authenticated = _identitySubject.value !== null;
        }

        function getIdentitySubject() {
            return _identitySubject;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identitySubject.value || !_identitySubject.value.Authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identitySubject.value.Authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.Authorities && _id.Authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {
            var deferred = $q.defer();

            if (force === true || !angular.isDefined(_identitySubject.value)) {
                Account.get().$promise
                    .then(getAccountThen)
                    .catch(getAccountCatch);
            } else {
                deferred.resolve(_identitySubject.value);
            }

            return deferred.promise;

            function getAccountThen (account) {
                _identitySubject.onNext(account.data);
                _authenticated = true;
                deferred.resolve(_identitySubject.value);
            }

            function getAccountCatch () {
                _identitySubject.onNext(null);
                _authenticated = false;
                deferred.resolve(_identitySubject.value);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identitySubject.value);
        }
    }
})();
