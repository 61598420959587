(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('apiInterceptor', apiInterceptor);

    apiInterceptor.$inject = ['API_URL'];

    function apiInterceptor (API_URL) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            if (config && config.url && (/^api/.test(config.url) || /^token/.test(config.url))) {
                config.url = API_URL + '/' + config.url;
            }

            return config;
        }
    }
})();