(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('EncuestasClienteDetailController', EncuestasClienteDetailController);

    EncuestasClienteDetailController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'encuesta', '$window'];

    function EncuestasClienteDetailController ($scope, $stateParams, $uibModalInstance, encuesta, $window) {
        var vm = this;
        vm.clear = clear;
        vm.encuesta = encuesta;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
            $window.history.back();
        }
    }
})();
