(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('HistorialComprasService', HistorialComprasService);

    HistorialComprasService.$inject = ['$q', '$http', '$stateParams'];

    function HistorialComprasService ($q, $http, $stateParams) {
        return {
            queryHistorialCompras: queryHistorialCompras,
            queryCompra: queryCompra,
            queryAllHistorialCompras: queryAllHistorialCompras,
            uploadReceipt: uploadReceipt
        };

        function queryHistorialCompras() {
            var deferred = $q.defer();

            $http.get('api/compras')
                .success(function(data) {
                    deferred.resolve({
                        historialCompras: data.Content
                    });
                }).error(function(msg) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        function queryCompra(id) {
            var deferred = $q.defer();

            $http.get('api/compras/' + id)
                .success(function(data) {
                    deferred.resolve({
                        compra: data
                    });
                }).error(function(msg) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        function queryAllHistorialCompras (size, number) {
            var deferred = $q.defer();
            var size = 20;
            var number = number;

            if(!number){
                number = 0;
            }

            $http.get('api/compras?Size='+ size + '&Number=' + number)
                .success(function(data) {
                    deferred.resolve({
                        historialCompras: data.Content
                    });
                }).error(function(msg) {
                deferred.reject(msg);
            });

            return deferred.promise;
        }

        function uploadReceipt($file, id) {
            if($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                var base64Data = "";

                fileReader.onload = function (e) {
                    base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);

                    var compraDTO = {};
                    compraDTO.Id = id;
                    compraDTO.FacturaFile = base64Data;

                    $http.post('api/compras/UploadFacturaFile', compraDTO).then(function(response) {
                        if(response.status == 200)
                            window.alert("Carga exitosa");
                        else
                            window.alert("Error al cargar archivo");
                    });
                };
            }
        }
    }
})();