(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboard', {
            parent: 'app',
            url: '/dashboard',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/dashboard/dashboard.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm',
                    resolve: {
                        encuestas: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.queryEncuestasCliente();
                        }],
                        encuestasSummary: ['EncuestasClienteService', function (EncuestasClienteService) {
                            return EncuestasClienteService.summary();
                        }],
                        membership: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getUserMembership();
                        }],
                        contactosLibres: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getContactosLibres();
                        }],
                        nextMembership: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getNextMembership();
                        }]
                    }
                }
            }
        });
    }
})();