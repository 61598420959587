(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('membresia', {
            parent: 'app',
            url: '/membresia',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/membresia/membresia.html',
                    controller: 'MembresiaController',
                    controllerAs: 'vm',
                    resolve: {
                        memberships: ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.queryMemberships();
                        }],
                        // userMembership : ['MembresiaService', function (MembresiaService) {
                        //     return MembresiaService.getUserMembership();
                        // }],
                        logged : ['Principal', function (Principal) {
                            return Principal.isAuthenticated();
                        }]
                    }
                }
            }
        })
        .state('membresia.contratar', {
            parent: 'membresia',
            url: '/contratar/{id}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'MembresiaService', function($stateParams, $state, $uibModal, MembresiaService) {
                $uibModal.open({
                    templateUrl: 'app/components/membresia/membresiaContratar-dialog.html',
                    controller: 'MembresiaContratarDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        membership : ['MembresiaService', function (MembresiaService) {
                            return MembresiaService.getMembership($stateParams.id);
                        }],
                        userMembership : function () {
                            return MembresiaService.getUserMembership();
                        },
                        userCard : ['CarritoService', function (CarritoService) {
                            return CarritoService.getCard();
                        }]
                    }
                }).result.then(function() {
                    $state.go('membresia', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();