(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .component('cardForm', {
            templateUrl: 'app/components/cardForm/cardForm.html',
            controller: 'CardFormController',
            controllerAs: 'vm',
            bindings: {
                card: '='
            }
        });
})();
