// TODO: hacer como los demas services (estaba apurado por la demo)

(function () {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .factory('User', User)
        .factory('UserByFacebook', UserByFacebook);

    User.$inject = ['$resource'];
    UserByFacebook.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:id', {}, {
            'query': { method: 'GET' },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;
    }

    function UserByFacebook ($resource) {
        var service = $resource('api/users/facebook/:facebookId', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
