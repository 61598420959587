(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$window', 'CarritoProvider', '$timeout'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $window, CarritoProvider, $timeout) {
        var vm = this,
            _identitySubscription,
            _carritoSubscription;

        function init() {
            vm.isNavbarCollapsed = true;
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.isAdmin = Principal.identity();
            vm.pending = 0;
            vm.$state = $state;
            vm.isAdmin = false;
            Principal.identity().then(function (result) {
                if(result){
                    angular.forEach(result.Authorities, function(auth){
                        if(auth == 'ROLE_ADMIN'){
                            vm.isAdmin = true;
                        }
                    });
                }
            });

            angular.element($window).bind("scroll", function() {
                var principalMenuLi = $(".principal-menu-li"),
                    principalMenu = $(".principal-menu");

                if (this.pageYOffset >= 52) {
                    principalMenuLi.addClass('scrolled');
                    principalMenu.addClass('scrolled');
                } else {
                    principalMenuLi.removeClass('scrolled');
                    principalMenu.removeClass('scrolled');
                }
            });

            _identitySubscription = Principal.getIdentitySubject().subscribe(function (value) {
                vm.user = value;
            });

            _carritoSubscription = CarritoProvider.getCarritoSubject().subscribe(function (value) {
                if (vm.pending < value.length) {
                    $('#carritoNotification').removeClass('bounce');

                    $timeout(function () {
                        $('#carritoNotification').addClass('bounce');
                    }, 400);
                }

                vm.pending = value.length;
            });
        }

        vm.login = function() {
            vm.collapseNavbar();
            LoginService.open();
        };

        vm.logout = function() {
            vm.collapseNavbar();
            Auth.logout();
            $state.go('home', {},{reload:true});
        };

        vm.toggleNavbar = function() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            if(!vm.isNavbarCollapsed) {
                document.getElementById('navbar-collapse').style.marginBottom = "100vh";
            }
            else {
                document.getElementById('navbar-collapse').style.marginBottom = "0";
            }
        };

        vm.collapseNavbar = function() {
            vm.isNavbarCollapsed = true;
        };

        init();
    }
})();
