(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('ClienteManagementDialogController',ClienteManagementDialogController);

    ClienteManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'Cliente', 'DataUtils', '$scope'];

    function ClienteManagementDialogController ($stateParams, $uibModalInstance, entity, Cliente, DataUtils, $scope) {
        var vm = this;

        function init () {
            vm.cliente = entity;
            if(vm.cliente.Id == null){
                vm.cliente.DisplayOnlyAuditedContacts = true;
            }
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.save = function () {
            vm.isSaving = true;
            if (vm.cliente.Id !== null) {
                Cliente.update(vm.cliente, onSaveSuccess, onSaveError);
            } else {
                Cliente.save(vm.cliente, onSaveSuccess, onSaveError);
            }
        };

        vm.abbreviate = DataUtils.abbreviate;

        vm.byteSize = DataUtils.byteSize;

        vm.setImagen = function ($file, imagen) {
            if ($file && $file.$error == 'pattern') {
                return;
            }

            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply(function() {
                        vm.cliente.Logo = base64Data;
                        vm.cliente.LogoContentType = $file.type;
                    });
                };
            }
        };

        vm.clearImage = function() {
            vm.cliente.Logo = null;
            vm.cliente.LogoContentType = null;
        };

        init();
    }
})();
