(function() {
    'use strict';

    angular
        .module('smarteyesjhApp')
        .controller('MembresiaController', MembresiaController);

    MembresiaController.$inject = ['memberships', 'logged', 'MembresiaService', 'Principal', '$window',
                                    '$stateParams', '$location','LoginService', 'CarritoProvider', '$uibModal'];

    function MembresiaController (memberships, logged, MembresiaService, Principal, $window,
                                  $stateParams, $location, LoginService, CarritoProvider, $uibModal) {
        var vm = this;

        vm.faltaLogin = 'Es necesario estar logueado para poder contratar una membresia.';

        function init () {
            vm.isGettingToken = false;

            // var agreementToken = $location.search().token;
            // if(agreementToken != null) {
            //     vm.isGettingToken = true;
            //     MembresiaService.executeAgreement(agreementToken).then(function(result) {
            //         vm.isGettingToken = false;
            //         $uibModal.open({
            //             templateUrl: 'app/components/membresia/confirmacionMembresia-dialog.html',
            //             controller: 'ConfirmacionMembresiaController',
            //             controllerAs: 'vm',
            //             size: 'md'
            //         });
            //     });
            // }

            vm.isAuthenticated = Principal.isAuthenticated;
            vm.memberships = memberships;
            vm.memberships.map(function (x) {
                x.Items.sort(function (a, b) {
                    return b.Destacado - a.Destacado;
                });
                return x;
            });



            if(logged){
                MembresiaService.getUserMembership().then(function(result){
                    vm.userMembership = result;
                });

            }
            
            angular.forEach(vm.memberships, function(membership){
               membership.PrecioFinal = membership.Precio - ((membership.Precio * membership.Descuento) / 100) ;
            });
            vm.logged = logged;
        }

        vm.suscribe = function (membresiaId) {
            if(!vm.logged) {
                LoginService.open();
                return;
            }

            console.log('nicooo', vm.userMembership);
            if(vm.userMembership
                && vm.userMembership.Id === membresiaId) {
                //ya tiene la membresia contratada
                console.log('membresia contratada');
                return;
            }
            //llamo al servicio de be que crea la suscripcion a la membresia seleccionada
            MembresiaService.suscribe(membresiaId).then(function(response) {
                console.log("membresia enviada al carrito");
                $uibModal.open({
                    templateUrl: 'app/components/membresia/confirmacionMembresia-dialog.html',
                    controller: 'ConfirmacionMembresiaController',
                    controllerAs: 'vm',
                    size: 'md'
                }).closed.then(function () {
                    MembresiaService.getUserMembership().then(function(result){
                        vm.userMembership = result;
                    });
                });
            });


        };

        vm.setMembresiaButtonText = function(index){
            var message = 'CONTRATAR';
            if(vm.userMembership != null && vm.memberships[index].Id == vm.userMembership.Id){
                if(vm.userMembership.Active){
                    message = 'CONTRATADA';
                }
                if(!vm.userMembership.Active && !vm.userMembership.Carrito){
                    message = 'PENDIENTE DE APROBACION';
                }
                if(vm.userMembership.Carrito){
                    message = 'ENVIADA AL CARRITO';
                }
            }

            return message;
        };

        window.scrollTo(0, 0);

        init();
    }
})();
